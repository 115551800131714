import { HColor } from "@shared/src/datatypes/HColor";
import { HDate, } from "@shared/src/datatypes/HDate";
import { HHourInterval } from "@shared/src/datatypes/HHourInterval";
import { HTags } from "@shared/src/datatypes/HTags";
import { GeneralStatus } from "@shared/src/enums/GeneralStatus";
import { PhaseSlotLogTypes } from "@shared/src/public-api";

export class PhaseSlotDto {

	public phaseId: number;
	public slotId: number;
	public routeId: number;
	public routeKey: string;

	public addressSkin: string;
	public title: string;
	public subtitle: string;
	public stops: HTags;
	public line1: string;
	public line2: string;
	public line3: string;
	public generalStatus: GeneralStatus;
	public isBlockedBookSlotSystem: boolean;
	public isBlockedEnterSystem: boolean;
	public isBlockedExitSystem: boolean;
	public isSlotBlocked: boolean;
	public isSlotBlockedExternal: boolean;
	public incompatibleSlot: boolean;
	public phaseClientDestinationTags: HTags;
	public overbooked: boolean;
	public invalidReservation: boolean;
	public routeManualTags: HTags;
	public tags: HTags;
	public internalTags: HTags;
	public phaseManualTags: HTags;
	public phaseUserTags: HTags;
	public isLateAtSlot: boolean;
	public isInbound: boolean;
	public isReturn: boolean;
	public isReverseLogistics: boolean;
	public isNonacceptance: boolean;
	public isOutbound: boolean;
	public isDelegate: boolean;
	public onDateSlot: HDate;
	public intervalSlot: HHourInterval;
	public intervalSlotDock: HHourInterval;
	public intervalSlotLift: HHourInterval;
	public slotStatus: PhaseSlotLogTypes;
	public dockSkin: string;
	public dockBackgroundColor: HColor;
	public hasBannerHtml: boolean;

	public partnerDriverId: number;
	public partnerSecondDriverId: number;
	public partnerCarrierId: number;
	public partnerShipperId: number;
	public partnerLogisticOperatorId: number;
	public partnerInspectorId: number;
	public partnerHealthCertifierId: number;
	public partnerClientPreferentId: number;

	public partnerParentTrademark: string;
	public partnerDriverTrademark: string;
	public partnerSecondDriverTrademark: string;
	public partnerCarrierTrademark: string;
	public partnerShipperTrademark: string;
	public partnerLogisticOperatorTrademark: string;
	public partnerInspectorTrademark: string;
	public partnerHealthCertifierTrademark: string;
	public partnerClientPreferentTrademark: string;

	public isGrouped: boolean;
	public isChildOfGrouped: boolean;

	public typology: string;

	public json: string;

	public order: string;
	public __searchString: string;

	public routeInternalTags: HTags;

	public static ACTION_NONE: string = "NONE";
	public static ACTION_INFO: string = "INFO";
	public static ACTION_ASSIGN: string = "ASSIGN";
	public static ACTION_MOVE: string = "MOVE";
	public static ACTION_SWAP: string = "SWAP";
	public static ACTION_REMOVE: string = "REMOVE";
	public static ACTION_REDUCE: string = "REDUCE";
	public static ACTION_ENLARGE: string = "ENLARGE";
	public static ACTION_ASSIGN_DOCK: string = "ASSIGN_DOCK";
	public static ACTION_REMOVE_DOCK: string = "REMOVE_DOCK";
	public static ACTION_NONACCEPTANCE: string = "NONACCEPTANCE";
	public static ACTION_RETURNS: string = "RETURNS";
	public static ACTION_INBOUNDS: string = "INBOUNDS";
	public static ACTION_OUTBOUNDS: string = "OUTBOUNDS";
	public static ACTION_DELEGATE: string = "DELEGATE";
	public static ACTION_REVERSELOGISTICS: string = "REVERSELOGISTICS";
}