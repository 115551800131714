import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { OpenResource } from '@shared/src/dtos/open/OpenResource';
import { iPhaseSlot } from '@shared/src/dtos/phase/iPhaseSlot';
import { AddressTimetableIntervalDto, AlertService, HDate, HHourInterval, HString, PhaseController, SharedSlotPickerComponent } from '@shared/src/public-api';

@Component({
    selector: 'shared-totem-slotpicker-component',
    templateUrl: './shared.totem.slotpicker.component.html',
    styleUrls: ['./shared.totem.slotpicker.component.scss']
})
export class SharedTotemSlotPickerComponent implements OnInit {
    constructor(protected phaseController: PhaseController, protected activatedRoute: ActivatedRoute, public dialog: MatDialog,
        public openRouteController: OpenRouteController, public fileUpDownloadController: FileUpDownloadController, public shipmentController: ShipmentController, public systemController: SystemController,
        @Inject('AlertService') public alertService: AlertService) {
    }

    ngOnInit(): void {
    }

    public __totem: TotemDto;
    @Input()
    public set totem(value: TotemDto) {
        if (this.__totem == value)
            return;
        this.__totem = value;
        this.getInfo();
    }
    public get totem(): TotemDto {
        return this.__totem;
    }

    @Input()
    public readonly: boolean = false;

    public realDate: HDate;
    public nooptions: boolean = false;
    public showObservacions: boolean = false;
    public canSlotRelease: Boolean = false;
    public realInterval: HHourInterval;
    public loading = true;
    public phase: any;
    getInfo() {
        this.getSlots();
    }

    getSlots() {
        this.loading = true;
        this.nooptions = false;
        this.canSlotRelease = false;

        if (this.totem != null && this.totem.identificationSelected != null) {

            this.phaseController.getByPhaseId(this.totem.identificationSelected.phaseId).subscribe(p => {
                this.phase = p;
                if (this.phase != null && !this.phase.isSlotBlocked) {
                    this.phaseController.getIntervalsByPhaseIdForTotem(this.phase.phaseId).subscribe(data => {
                        this.intervals = data;
                        this.loading = false;
                        if (!this.canSlotRelease && this.intervals != null && this.intervals.length == 0) {
                            this.nooptions = true;
                        }
                    });

                }

                if (this.phase != null && this.phase.intervalInfo != null && this.phase.intervalInfo.addressTimetableIntervalId != 0) {
                    this.showObservacions = SharedSlotPickerComponent.getShowObservacions(this.phase.intervalInfo);
                    if (this.showObservacions) {
                        this.realDate = SharedSlotPickerComponent.getRealDate(this.phase.intervalInfo);
                        this.realInterval = SharedSlotPickerComponent.getRealInterval(this.phase.intervalInfo);
                    }
                }
            });
        }

    }
    public getRealIntervalFunc = SharedSlotPickerComponent.getRealInterval;
    public getRealDateFunc = SharedSlotPickerComponent.getRealDate;
    public getShowObservacionsFunc = SharedSlotPickerComponent.getShowObservacions;
    isSelected(addressinterval: AddressTimetableIntervalDto) {
        return addressinterval != null
            && this.phase != null
            && this.phase.intervalInfo != null
            && this.phase.intervalInfo.addressTimetableIntervalId === addressinterval.addressTimetableIntervalId;
    }

    @Output()
    slotChanged: EventEmitter<void> = new EventEmitter();
    onIntervalSelected(addressinterval: AddressTimetableIntervalDto) {
        if (this.phase == null || this.phase.phaseId == null)
            return;
        if (addressinterval == null || HHourInterval.isNullOrNullValue(addressinterval.interval))
            return;
        if (this.isSelected(addressinterval))
            return;

        if (!addressinterval.assignable)
            return;

        if (addressinterval.addressTimetableIntervalId != null)
            this.openRouteController.phaseAllocateSlot(this.phase.phaseId, addressinterval.addressTimetableIntervalId).subscribe((data: Boolean) => {
                this.slotChanged.next();
            });
        else if (addressinterval.addressDaySettingResourceId != null) {
            this.openRouteController.phaseAllocateSlotByResource(this.phase.phaseId, addressinterval.addressDaySettingResourceId, OpenResource.build(addressinterval)).subscribe((data: Boolean) => {
                this.slotChanged.next();
            });
        }

    }

    private _intervals: Array<AddressTimetableIntervalDto>
    public set intervals(value: Array<AddressTimetableIntervalDto>) {
        if (this._intervals === value)
            return;
        this._intervals = value;
        this.loadIntervals();
    }
    public get intervals(): Array<AddressTimetableIntervalDto> {
        return this._intervals;
    }

    public addressintervals: AddressTimetableIntervalDto[];
    loadIntervals() {
        this.addressintervals = [];

        if (!this.intervals)
            return;

        if (this.intervals == null || this.intervals.length <= 0)
            return;

        this.intervals.forEach(element => {
            if (!HHourInterval.isNullOrNullValue(element.interval))
                this.addressintervals.push(element);
        });

        if (this.phase == null || this.phase.intervalInfo == null)
            return;
        if (HHourInterval.isNullOrNullValue(this.phase.intervalInfo.interval))
            return;
        /*
        * Afegeix l'interval reservat, indicat per slotInfo, quan no està present al llistat d'intervals
        * d'una fase retornats pel back.
        * */
        let isInTheList = false;
        for (const addressinterval of this.addressintervals) {
            if (addressinterval.addressTimetableIntervalId === this.phase.intervalInfo.addressTimetableIntervalId) {
                isInTheList = true;
                break
            }
        }
        if (!isInTheList) {
            this.addressintervals.push(this.phase.intervalInfo);
        }

    }
    /***
         * True si el n-essim element item te una data diferent a l'anterior
         */
    public trencaData(i: number, item: AddressTimetableIntervalDto): boolean {
        if (i == 0)
            return true;
        let prev = this.addressintervals[i - 1];
        return !HDate.equals(item.onDate, prev.onDate);
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
