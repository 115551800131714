<ng-container *ngIf="totem">
    <div class="page-title">
        {{getTitle() | translateIn:totem.language | async}}
    </div>
    <div class="column-container">
        <div class="column-central">
            <input class="searchbox" placeholder="{{getPrompt() | translateIn:totem.language | async}}" type=text
                [(ngModel)]="totem.driverPhone" *ngIf="totem.step==totemSteps.enterVerifyDriverPhone">
            <input class="searchbox" placeholder="{{getPrompt() | translateIn:totem.language | async}}" type=text
                [(ngModel)]="totem.plateA" *ngIf="totem.step==totemSteps.enterVerifyPlateA">
            <input class="searchbox" placeholder="{{getPrompt() | translateIn:totem.language | async}}" type=text
                [(ngModel)]="totem.plateB" *ngIf="totem.step==totemSteps.enterVerifyPlateB">
            <input class="searchbox" placeholder=" {{getPrompt() | translateIn:totem.language | async}}" type=text
                [(ngModel)]="totem.driverLegalId" *ngIf="totem.step==totemSteps.enterVerifyDriversLegalId">
            <input class="searchbox" placeholder="{{getPrompt() | translateIn:totem.language | async}}" type=text
                [(ngModel)]="totem.driverName" *ngIf="totem.step==totemSteps.enterVerifyDriversName">
            <input class="searchbox" placeholder="{{getPrompt() | translateIn:totem.language | async}}" type=text
                [(ngModel)]="totem.carrierLegalId" *ngIf="totem.step==totemSteps.enterVerifyDriversCarrierLegalId">
            <input class="searchbox" placeholder="{{getPrompt() | translateIn:totem.language | async}}" type=text
                [(ngModel)]="totem.carrierName" *ngIf="totem.step==totemSteps.enterVerifyDriversCarrierName">
            <input class="searchbox" placeholder="{{getPrompt() | translateIn:totem.language | async}}" type=text
                [(ngModel)]="totem.carrierAddress" *ngIf="totem.step==totemSteps.enterVerifyDriversCarrierAddress">
            <div class="page-help" [innerHtml]="getHelp() | translateIn:totem.language | async">
            </div>
        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta">
                <button class="button-action" (click)="buttonOk()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{'TOTEMPAGE.DATACONFIRMATION.BUTTON.CODE'| translateIn:totem.language | async}}
                    </div>
                    <div class="name">{{'TOTEMPAGE.DATACONFIRMATION.BUTTON.NAME'| translateIn:totem.language | async}}
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-container>