import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { RouteCardDto } from "@shared/src/public-api";
import { RouteDividedInfoDto } from "@shared/src/dtos/route/RouteDividedInfoDto";
import { HLong } from "@shared/src/datatypes/HLong";

@Component({
    selector: 'shared-route-division',
    templateUrl: './shared.route.division.component.html',
    styleUrls: ['./shared.route.division.component.scss']
})
export class SharedRouteDivisionComponent implements OnInit {

    public readonly: boolean = false;

    constructor(public routeController: RouteController, @Inject('HomeController') public homeController: HomeController, @Inject('SecurityController') public securityController: SecurityController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });
    }

    private _routeId: number;
    @Input()
    public set routeId(value: number) {
        if (this._routeId === value)
            return;

        this._routeId = value;
        if(!HLong.isNullOrNullLong(this._routeId)){
            this.routeController.getRoute(this._routeId).subscribe(data => {
                this.route = data;
            });
        }

    }
    public get routeId(): number {
        return this._routeId;
    }

    private _route: RouteCardDto;
    @Input()
    public set route(value: RouteCardDto) {
        if (this._route === value)
            return;

        this._route = value;
        this.numChilds = value.childs ? value.childs.length : this.numChilds;
        this.wellcome();
    }
    public get route(): RouteCardDto {
        return this._route;
    }

    public numChilds: number = 2;

    @Output() public refreshRoute: EventEmitter<any> = new EventEmitter<any>();
    onRouteDivision() {
        if (this.route == null || HLong.isNullOrNullLong(this.route.routeId))
            return;
        this.routeController.routeDivision(this.route.routeId, this.numChilds).subscribe(data => {
            this.route = data;
            this.refreshRoute.next(data);
        });
    }

    public routeDividedInfo: RouteDividedInfoDto = RouteDividedInfoDto.empty();
    wellcome() {
        if (this.route == null || HLong.isNullOrNullLong(this.route.routeId))
            return;
        this.routeController.getDividedInfo(this.route.routeId).subscribe(data => {
            this.routeDividedInfo = data;
        });
    }

    ngOnInit(): void {
    }

}
