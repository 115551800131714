<ng-container *ngIf="totem">
    <div class="header">
        <div class="esquerra">
            <img *ngIf="logoPropi" src="/assets/images/HardmanLogoBlauNom.png" alt="Hardman logo"
                class="logo logoambnom">
        </div>
        <div class="centre">
            <img *ngIf="!logoPropi" src="/assets/images/HardmanLogoBlauNom.png" alt="Hardman logo"
                class="logo logoambnom">
            <shared-image *ngIf="logoPropi" class="logo" [image]="logoImage">
            </shared-image>
            <div class="totem-name">{{totem.caption}} {{totem.step}}</div>
        </div>
        <div class="dreta column-dreta">
            <div class="container-button-back">
                <button class="button-back" *ngIf="totem.canGoBack" (click)="onBackClick()">
                    <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
                    <div class="code">{{totem.backCode | translateIn:totem.language | async}}</div>
                    <div class="name">{{totem.backName | translateIn:totem.language | async}}</div>
                </button>
            </div>
            <button class="button-reset" *ngIf="this.totem.checkInactivity" (click)="onResetClick()">
                <i class="fa fa-times-circle" aria-hidden="true"></i>
                <div class="progress-bar" [ngStyle]="{'width.%': inactivityProgress}">
                </div>
                <div class="code">{{'TOTEMPAGE.RESET.CAPTION' | translateIn:totem.language | async}}</div>
            </button>
        </div>
    </div>
    <div class="body">
        <ng-container *ngIf="totem.step==totemSteps.language">
            <div class="webcam-container">
                <shared-webcam class="nodimensions qrscanner nobuttons"></shared-webcam>
            </div>
            <div class="options languages">
                <button class="box language-box" *ngFor="let l of totem.languages | sortByLanguagePoints"
                    (click)="onLanguageClick(l)">
                    <div class="code language">{{getLanguageCaption(l) | translateIn:totem.language | async}}</div>
                    <div class="name">{{getLanguageName(l) | translateIn:totem.language | async}}</div>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="totem.step==totemSteps.action">
            <div class="page-title">
                {{'TOTEMPAGE.ACTION.TITLE' | translateIn:totem.language | async}}
            </div>
            <div class="options actions">
                <button class="box" *ngFor="let l of totem.actions | sortBy:'order'" [ngClass]="l.action"
                    (click)="onActionClick(l)">
                    <div class="code">{{getActionCaption(l) | translateIn:totem.language | async}}</div>
                    <div class="name">{{getActionName(l) | translateIn:totem.language | async}}</div>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="totem.step==totemSteps.operation">
            <div class="page-title">
                {{'TOTEMPAGE.OPERATION.TITLE' | translateIn:totem.language | async}}
            </div>
            <div class="options operations">
                <button class="box" *ngFor="let l of totem.operations | sortBy:'order'" [ngClass]="l.operation"
                    (click)="onOperationClick(l)">
                    <div class="code">{{getOperationCaption(l) | translateIn:totem.language | async}}</div>
                    <div class="name">{{getOperationName(l) | translateIn:totem.language | async}}</div>
                </button>
            </div>
        </ng-container>
        <shared-totem-identification *ngIf="totem.step==totemSteps.identification" [(totem)]="totem"
            (onFinish)="next()"></shared-totem-identification>
        <shared-totem-idverification *ngIf="totem.step==totemSteps.idverification" [(totem)]="totem"
            (onFinish)="next()"></shared-totem-idverification>
        <shared-totem-addmore *ngIf="totem.step==totemSteps.addMore" [(totem)]="totem"
            (onFinish)="next()"></shared-totem-addmore>
        <shared-totem-enter-dataconfirmation *ngIf="isEnterDataConfirmationStep(totem.step)" [(totem)]="totem"
            (onFinish)="next()"></shared-totem-enter-dataconfirmation>
        <shared-totem-slot *ngIf="isSlotStep(totem.step)" [(totem)]="totem" (onRefresh)="refreshTotem()"
            (onFinish)="next()"></shared-totem-slot>
        <shared-totem-sign *ngIf="isSignatureStep(totem.step)" [(totem)]="totem" (onRefresh)="refreshTotem()"
            (onFinish)="next()"></shared-totem-sign>
        <shared-totem-exit *ngIf="isExitStep(totem.step)" [(totem)]="totem" (onFinish)="next()"></shared-totem-exit>
        <shared-totem-estadins *ngIf="isEstaDins(totem.step)" [(totem)]="totem" (onRefresh)="refreshTotem()"
            (onFinish)="next()"></shared-totem-estadins>
        <shared-totem-enterfinish *ngIf="isEnterFinish(totem.step)" [(totem)]="totem" (onRefresh)="refreshTotem()"
            (onFinish)="next()"></shared-totem-enterfinish>
    </div>
</ng-container>
<ng-container *ngIf="!totem">
    Loading totem ...
</ng-container>
<shared-totem-loading *ngIf="processing" [resource]="'SHARED.TOTEM.LOADING.COMPONENT.PROCESSING'"
    [totem]="totem"></shared-totem-loading>