<ng-container *ngIf="value">
    <div class="bar no-justsignature">
        <div class="baroptions">
            <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
    </div>

    <div class="title no-justsignature">{{'SHAREDSIGNCMRMAINTENANCECOMPONENT.NEWSIGN.TITLE'|translate:{type:
        traduirTipus(type)}
        }}
    </div>
    <div class="signatura-nova" *ngIf="!value.isSigned && !applyReadOnly">
        <div class="block-signatura">
            <shared-signature-component [fileSignatureBytes]="fileSignatureBytes"
                [fileSignatureType]="fileSignatureType" (endDrawSignature)="onEndDrawSignature($event)"
                (clearSignature)="onClearSignature($event)">
            </shared-signature-component>
            <div class="signatura-filainferior no-justsignature">
                <button *ngIf="fileSignatureBytes" class="cancel" (click)="cancelSignature()">{{'SHARED.SIGN.CMR.CANCEL.BUTTON' | translate}}</button>
                <button *ngIf="fileSignatureBytes" class="add green" (click)="doSignature()">{{'SHARED.SIGN.CMR.SIGN.BUTTON' | translate}}</button>
                
            </div>

            <boolean-component *ngIf="value.showPrintCheck" class="checkbox" [(ngModel)]="printDocumentation" [showHelpPoint]=false
                placeholder="{{'SHARED.SIGN.CMR.PRINTDOCUMENT.CAPTION' | translate }}">
            </boolean-component>
        </div>
        <div class="block-documentacio no-justsignature">
            <p [innerHTML]="value.helpMessage | safe: 'html'"></p>
        </div>
    </div>
    <ng-container *ngIf="value.isSigned || applyReadOnly">
        <div class="block-signatura" (click)="clickModeSignatura()" [ngClass]="classModeSignatura">
            <shared-signature-component [fileSignatureBytes]="value.fileContent" [fileSignatureType]="value.fileType"
                [readonly]="true" [ngClass]="{'invalida':!value.isValidSign}"></shared-signature-component>
            <div class="capa-posterior">
                <div class="line">{{value.userIdentification}}</div>
                <div class="line">{{value.signatureMoment | datehournosecondsnotzobserver}}</div>
                <div class="line">{{value.signatureCheckSum}}</div>
            </div>
        </div>
        <div class="block no-justsignature">
            <div class="menu inferior">
                <button class="link" (click)="showHistory=!showHistory">{{'SHARED.SIGN.CMR.HISTORY.BUTTON' |
                    translate}}</button>
                <button class="link" (click)="newSign()">{{'SHARED.SIGN.CMR.NEWSIGN.BUTTON' | translate}}</button>
            </div>
        </div>

        <div class="block no-justsignature print" *ngIf="value.isSigned && value.showPrintButton">
            <button class="action" (click)="doPrint()">{{'SHARED.SIGN.CMR.PRINT.BUTTON' | translate}}</button>
        </div>

        <div class="block margin no-justsignature">
            <shared-sign-cmr-log *ngIf="showHistory" [type]="type" [routePartCMR]="routePartCMR" [signUUID]="signUUID"></shared-sign-cmr-log>
        </div>
    </ng-container>
</ng-container>