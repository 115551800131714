import { Component, Input } from '@angular/core';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
    selector: 'shared-totem-loading',
    templateUrl: './shared.totem.loading.component.html',
    styleUrls: ['./shared.totem.loading.component.scss'],
    animations: [
        trigger('fadeAnimation', [
            state('void', style({ opacity: 0 })), // Estat inicial quan l'element entra o surt
            transition(':enter', [ // Animació d'entrada
                animate('1000ms ease-in', style({ opacity: 1 }))
            ]),
            transition(':leave', [ // Animació de sortida
                animate('1000ms ease-out', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class SharedTotemLoadingComponent {

    constructor() {

    }
    @Input()
    public totem: TotemDto;

    @Input()
    public resource: string = 'SHARED.TOTEM.LOADING.COMPONENT.LOADING';

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


