import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { HString, HTranslateService, PhaseController } from '@shared/src/public-api';
import { TotemController } from '@shared/src/controllers/totem/totem.controller';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { TotemSteps } from '@shared/src/controllers/totem/TotemSteps';
import { RoutePartCMRSignatureAgents } from '@shared/src/controllers/route/cmr/RoutePartCMRSignatureAgents';

@Component({
    selector: 'shared-totem-sign',
    templateUrl: './shared.totem.sign.component.html',
    styleUrls: ['./shared.totem.sign.component.scss']
})
export class SharedTotemSignComponent implements OnInit, OnDestroy {

    constructor(protected phaseController: PhaseController,
        protected totemService: TotemController,
        protected translateService: HTranslateService) {

    }

    public totemSteps = TotemSteps;

    ngOnInit(): void {
    }
    ngOnDestroy() {
    }

    private _totem: TotemDto;
    @Input()
    public set totem(value: TotemDto) {
        if (this._totem === value)
            return;
        this._totem = value;
        this.loadData();
    }
    public get totem(): TotemDto {
        return this._totem;
    }

    public agent: RoutePartCMRSignatureAgents = RoutePartCMRSignatureAgents.driver;
    public uuid: string = "";

    loadData(){
        if(this.totem != null && this.totem.identificationSelected != null){
            this.phaseController.getSignUUID(this.totem.identificationSelected.phaseId).subscribe(d => {
                this.uuid = d;
            });
        }
    }

    getUUID() {
        return this.uuid;
    }
    getAgent() {
        return this.agent;
    }

    onSignedChanged(){
        this.onRefresh.next();
    }

    @Output() onRefresh = new EventEmitter<any>();

    @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();

    public getButtonActionCode(): string {
        return "TOTEMPAGE.SIGN." + HString.toUpperCase(this.totem.step) + ".BUTTON.CODE";
    }

    public getButtonActionName(): string {
        return "TOTEMPAGE.SIGN." + HString.toUpperCase(this.totem.step) + ".BUTTON.NAME";
    }

    public buttonOk() {
        this.onFinish.next();
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


