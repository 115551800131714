import { NGX_MAT_DATE_FORMATS, NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Camera } from '@ionic-native/camera/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedGeneralStatusContainerComponent } from '@shared/src/components/route/generalState/shared.generalstatus.container.component';
import { SharedPhaseItemComponent } from '@shared/src/components/route/phase/shared.phase.item.component';
import { SharedRouteItemComponent } from '@shared/src/components/route/shared.route.item.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { ChartsModule } from 'ng2-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WebcamModule } from 'ngx-webcam';
import { NotrackPageComponent } from '../components/track/notrack-page/notrack-page.component';
import { TrackPageComponent } from '../components/track/track-page/track-page.component';
import { TrackContacteDialog } from '../components/track/track-page/track.contacte.dialog';
import { TrackDeliveryDialog } from '../components/track/track-page/track.delivery.dialog';
import { OrderActionDtoFilter } from '../dtos/maintenance/action/OrderActionDtoFilter';
import { avoidActionsFilter } from '../dtos/maintenance/action/avoidActionsFilter';
import { OrderOptionDtoFilter } from '../dtos/maintenance/option/OrderOptionDtoFilter';
import { avoidOptionsFilter } from '../dtos/maintenance/option/avoidOptionsFilter';
import { onlyOptionsFilter } from '../dtos/maintenance/option/onlyOptionsFilter';
import { DataDetall } from '../filters/DataDetall';
import { DataDetallDiaSetmana, DataHoraDetall, DataHoraDetallDiaSetmana, DataHoraDetallOnlyDiaSetmana } from '../filters/DataHoraDetall';
import { DataHoraMiniFase } from '../filters/DataHoraMiniFase';
import { DateHourFilter, DateHourIntervalFilter, DateHourIntervalObserverFilter, DateHourNoSecondsNoTzFilter, DateHourNoSecondsNoTzObserverFilter, DateHourNoSecondsObserverFilter, DateHourNoSecondsObserverReactiveFilter, DateHourNoSecondsReactiveFilter, DateHourNoTzFilter, DateHourNoTzObserverFilter, DateHourOnlyDateNoTzFilter, DateHourOnlyHourNoTzFilter, DateHourOnlyTimeZoneFilter, DateHourWithSecondsFilter, DateHourWithSecondsFilterNoTz, DateHouronlydateobserver, DateHouronlyhourobserver } from '../filters/DateHourFilter';
import { DateHourOnlyDateFilter } from '../filters/DateHourOnlyDateFilter';
import { DateHourOnlyHourFilter } from '../filters/DateHourOnlyHourFilter';
import { DiaDataDetall } from '../filters/DiaDataDetall';
import { ExcludeBookingsPipe } from '../filters/ExcludeBookingsPipe';
import { FilterActiveRoutesPipe } from '../filters/FilterActiveRoutesPipe';
import { FilterFromMeToYouLinks } from '../filters/FilterFromMeToYouLinks';
import { FilterPipe } from '../filters/FilterPipe';
import { FilterRemoveRepeat } from '../filters/FilterRemoveRepeat';
import { HTranslateFilter } from '../filters/HTranslateFilter';
import { HourFilter, HourFilterNotNull } from '../filters/HourFilter';
import { HourIntervalFilter, HourIntervalTotalFilter } from '../filters/HourIntervalFilter';
import { HourIntervalsFilter } from '../filters/HourIntervalsFilter';
import { HourIntervalsFilterHTML } from '../filters/HourIntervalsFilterHTML';
import { IntervalFilter } from '../filters/IntervalFilter';
import { MiniDia } from '../filters/MiniDia';
import { NumberFilter } from '../filters/NumberFilter';
import { OrderByAccessControlGatePlateDto } from '../filters/OrderByAccessControlGatePlateDto';
import { OrderByMenuOptionDto } from '../filters/OrderByMenuOptionDto';
import { OrderByRouteCardDto } from '../filters/OrderByRouteCardDto';
import { OrderClientProviderViewShipmentDto } from '../filters/OrderClientProviderViewShipmentDto';
import { PercentageFilter } from '../filters/PercentageFilter';
import { PhaseAddressData } from '../filters/PhaseAddressData';
import { EnterFilter, PriceFilter, QuantityFilter } from '../filters/PriceFilter';
import { SafePipe } from '../filters/SafePipe';
import { SortByIntervalProperty } from '../filters/SortByIntervalProperty';
import { SortByPipe } from '../filters/SortByPipe';
import { SortDocumentType } from '../filters/SortDocumentType';
import { SortLoadConsoleSlotPhaseDto } from '../filters/SortLoadConsoleSlotPhaseDto';
import { SortPermission } from '../filters/SortPermission';
import { TagFilter } from '../filters/TagFilter';
import { TelemetryDoubleFilter } from '../filters/TelemetryDoubleFilter';
import { TimeDaysFilter } from '../filters/TimeDaysFilter';
import { MillisecondsToMinutesSecondsMillis, TimeFilter, TimeFilterNotNull } from '../filters/TimeFilter';
import { TimeStampPipe, TimeStampPipeTz } from '../filters/TimeStampPipe';
import { TriStateFilter } from '../filters/TriStateFilter';
import { UTCFilter } from '../filters/UTC';
import { WithCountPipe } from '../filters/WithCountPipe';
import { KeysPipe } from '../filters/keysPipe';
import { MaterialAppModule } from '../material.app.module';
import { CameraService } from '../services/camera/camera.service';
import { FileService } from '../services/file/file.service';
import { DateValidator } from './../components/core/form/validations/DateValidator';
import { DateFilter, DateNoYearFilter } from './../filters/DateFilter';
import { SharedAccessControlPlateRouteInfoComponent } from './accesscontrol/plate/shared.accesscontrol.plateroute.info.component';
import { SharedAddressAmbitSelectorComponent } from './address/shared.address.ambit.selector.component';
import { SharedMapAddressComponent } from './address/shared.map.address.component';
import { SharedAssistedAddressComponent } from './assistedaddress/shared.assistedaddress.component';
import { SharedSelectorAddressmanualaddressComponent } from './assistedaddress/shared.selector.addressmanualaddress.component';
import { SharedBillTypeCreatePage } from './billtype/shared.billtype.create.page';
import { SharedBookingCardComponent } from './booking/bookingcard/shared.booking.card.component';
import { SharedContainerNumberComponent } from './booking/containernumber/shared.containernumber.component';
import { SharedBookingFormComponent } from './booking/form/shared.booking.form.component';
import { SharedBookingPartnerSelectorComponent } from './booking/partnerselector/shared.booking.partnerselector.component';
import { SharedBookingRouteSelectorComponent } from './booking/route/shared.booking.route.component';
import { SharedBookingPageComponent } from './booking/shared.booking.page.component';
import { SharedChatAvatar } from './chat/avatar/shared.chat.avatar';
import { SharedChatsIcon } from './chat/icon/shared.chats.icon';
import { SharedChatItem } from './chat/itemlist/shared.chat.item';
import { SharedChatMessage } from './chat/message/shared.chat.message';
import { SharedChat } from './chat/shared.chat';
import { SharedChats } from './chat/shared.chats';
import { SharedClientProviderViewComponent } from './clientprovider/shared.clientprovider.view.component';
import { SharedClientProviderViewShipmentCardComponent } from './clientprovider/shipment-card/shared.clientprovider.view.shipment.card.component';
import { SharedAddressAccessControlSelectorComponent, SharedBoxesComponent, SharedButtonLinkComponent, SharedCMREventsListComponent, SharedExworkPhaseCardComponent, SharedManagementModelRequirementItemComponent, SharedOpenDocumentComponent, SharedRouteBookingComponent, SharedRouteChangeConditionsComponent, SharedRouteChangeStatusComponent, SharedRouteChangeTagsComponent, SharedRouteExworksEditComponent, SharedRouteGroupPanelComponent, SharedRouteGroupPanelMaintenanceComponent, SharedRoutePartCMREditEventsComponent, SharedRouteStripComponent, SharedShipmentCMREditAdditionalDataComponent, SharedShipmentCMREditEventsComponent, SharedShipmentCMRMaintenanceComponent, SharedTruckingComponent, SharedTruckingRoutePanelComponent, SharedTruckingRouteCard, SharedTruckingRouteStripComponent, SharedTruckingRoutes, SharedTruckingShipmentCard, SharedTruckingShipmentStripComponent, SharedTruckingShipments, SharedPhaseChangeStrongAddressComponent, SharedReserveOutOfHoursBox, SharedTypologyTimeBox, SharedDocumentaryReviewRoutes, SharedDocumentaryReviewRouteItemComponent, SharedManagementModelInfoComponent, SharedDocumentaryReviewItemDocumentsComponent, SharedDocumentaryReviewDocumentsComponent, SharedExworkRoutePartCMRDigitalDocumentComponent, SharedPhaseCMRQRComponent } from './components.api';
import { AvatarComponent } from './core/avatar/avatar.component';
import { BooleanComponent } from './core/basics/boolean/boolean.component';
import { CheckboxComponent } from './core/basics/checkbox/checkbox.component';
import { ColorPickerComponent } from './core/basics/color/color-picker.component';
import { SharedCopyClipBoard } from './core/basics/copyclipboard/shared.copyclipboard';
import { DatePickerComponent } from './core/basics/date/date-picker.component';
import { DateHourPickerComponent } from './core/basics/datehour/datehour-picker.component';
import { DateHourViewerComponent } from './core/basics/datehour/datehour.viewer.component';
import { EntitySelectorComponent } from './core/basics/entity-selector/entity-selector.component';
import { EnumSelectorComponent } from './core/basics/enum-selector/enum-selector.component';
import { FileDragAndDropComponent } from './core/basics/file-drag-and-drop/file-drag-and-drop.component';
import { HourPickerComponent } from './core/basics/hour/hour-picker.component';
import { HourIntervalPickerComponent } from './core/basics/hourinterval/hourinterval-picker.component';
import { HourIntervalsPickerComponent } from './core/basics/hourintervals/hourintervals-picker.component';
import { InputComponent } from './core/basics/input/input.component';
import { LabelDateHourComponent } from './core/basics/label-datehour/label-datehour.component';
import { MapsSearchComponent } from './core/basics/maps-search/maps-search.component';
import { SharedSaveButton } from './core/basics/savebutton/shared.savebutton';
import { SelectComponent } from './core/basics/select/select.component';
import { TagsChipsComponent } from './core/basics/tags-chips/tags.chips.component';
import { TextAreaComponent } from './core/basics/textarea/textarea.component';
import { TimeCaretComponent } from './core/basics/time/caret/time-caret.component';
import { TimePickerComponent } from './core/basics/time/time-picker.component';
import { TriStateComponent } from './core/basics/tristate/tristate.component';
import { ButtonToggleComponent } from './core/button-toggle/button-toggle.component';
import { AddressCalendarDay } from './core/calendar/address/address.calendar.day';
import { AddressCalendarMonths } from './core/calendar/address/address.calendar.months';
import { CalendarDay } from './core/calendar/calendar.day';
import { CalendarMonth } from './core/calendar/calendar.month';
import { CalendarMonths } from './core/calendar/calendar.months';
import { SharedChartComponent } from './core/canvas/shared.chart.component';
import { SharedSimpleTableComponent } from './core/canvas/shared.simpletable.component';
import { SharedTableComponent } from './core/canvas/shared.table.component';
import { ConfirmationDialogDialogComponent } from './core/confirmationdialog/dialog/confirmationdialog.dialog.component';
import { SharedConfirmationDialogComponent } from './core/confirmationdialog/shared.confirmationdialog.component';
import { SharedDocumentViewComponent } from './core/documentview/shared.document.view.component';
import { DateHourValidator } from './core/form/validations/DateHourValidator';
import { HourIntervalValidator } from './core/form/validations/HourIntervalValidator';
import { HourIntervalsValidator } from './core/form/validations/HourIntervalsValidator';
import { HourValidator } from './core/form/validations/HourValidator';
import { TagsValidator } from './core/form/validations/TagsValidator';
import { TimeValidator } from './core/form/validations/TimeValidator';
import { HelpPointDialogComponent } from './core/helppoint/dialog/helppointdialog.component';
import { SharedHelpPointComponent } from './core/helppoint/shared.helppoint.component';
import { SharedIFrameItemDialog } from './core/iframe/shared.iframe.item.dialog';
import { SharedImageSliderDialog } from './core/images/imageslider/shared.imageslider.dialog';
import { SharedImageSliderMobileDialog } from './core/images/imageslidermobile/shared.imageslidermobile.dialog';
import { SharedImagesComponent } from './core/images/shared.images.component';
import { SharedActionsComponent } from './core/maintenance/actions/shared.actions.component';
import { OrderFilterDtoFilter } from './core/maintenance/filter/OrderFilterDtoFilter';
import { SharedFilterComponent } from './core/maintenance/filter/shared.filter.component';
import { SharedOptionsComponent } from './core/maintenance/options/shared.options.component';
import { SharedMenuOptionBurguerComponent } from './core/menu-burger/option/shared-menu-option-burguer.component';
import { SharedMenuBurguerComponent } from './core/menu-burger/shared-menu-burguer.component';
import { SharedSignatureComponent } from './core/signature/shared.signature.component';
import { SharedBookingStateComponent } from './core/state/booking/shared.booking.state.component';
import { SharedGeneralStatusViewerComponent } from './core/state/shared.general.status.viewer.component';
import { SharedStateComponent } from './core/state/shared.state.component';
import { SharedStateUserComponent } from './core/state/user/shared.state.user.component';
import { SharedTagsStripComponent } from './core/tags/shared.tags.strip.component';
import { SharedTagsToggleComponent } from './core/tags/shared.tags.toggle.component';
import { SharedWebCamComponent } from './core/webcam/shared.webcam.component';
import { SharedCustomTableColumnComponent } from './customtable/column/shared.customtable.column.component';
import { SharedCustomTableFieldComponent } from './customtable/field/shared.customtable.field.component';
import { SharedCustomTableComponent } from './customtable/shared.customtable.component';
import { SharedDockPickerComponent } from './docks/dock-picker/shared.dock.picker.component';
import { SharedDockPlanningComponent } from './docks/dock-planning/shared.dock.planning.component';
import { SharedDocksPlanningComponent } from './docks/docks-planning/shared.docks.planning.component';
import { SharedExworkRouteBookingComponent } from './exwork/routebooking/shared.exwork.routebooking.component';
import { SharedExworkRouteGroup } from './exwork/routegroup/shared.exwork.routegroup';
import { SharedExworkComponent } from './exwork/shared.exwork.component';
import { SharedExworkSlotPickerComponent } from './exwork/shared.exwork.slotpicker.component';
import { SharedFormQuestionAnswerShipmentItem } from './form/item/shared.form.question.answer.shipment.item';
import { SharedFormQuestionsAnswersShipment } from './form/shared.form.questions.answers.shipment';
import { SharedImageComponent } from './image/shared.image.component';
import { SharedSupervisionComponent } from './internal/supervision/shared.supervision.component';
import { SharedJourneyDetailComponent } from './journey/shared.journey.detail.component';
import { SharedLabelsComponent } from './label/shared.labels.component';
import { PartnerDetailComponent } from './link/detail/partner.detail.component';
import { SharedPartnerInfoCardComponent } from './link/infocard/shared.partner.infocard.component';
import { SharedLiteComponent } from './lite/shared.lite.component';
import { SharedLiteItemComponent } from './lite/shared.lite.item.component';
import { SharedLoadConsolePhaseInfoComponent } from './loadconsole/shared.loadconsole.phase.info.component';
import { SharedManagementModelChatComponent } from './managementmodel/chat/shared.managementmodel.chat.component';
import { SharedManagementModelRequirementsLogComponent } from './managementmodel/log/shared.managementmodel.requirements.log.component';
import { SharedManagementModelRequirementsComponent } from './managementmodel/requirement/shared.managementmodel.requirements.component';
import { SharedManagementModelItemComponent } from './managementmodel/item/shared.managementmodel.item.component';
import { SharedMapInfoWindowComponent } from './map/info/shared.map.info.window.component';
import { SharedMapComponent } from './map/shared.map.component';
import { SharedMapRouteComponent } from './map/shared.map.route.component';
import { SharedPanelElementLogComponent } from './panel/info/log/shared.panel.element.log.component';
import { SharedPanelElementInfoComponent } from './panel/info/shared.panel.element.info.component';
import { SharedPanelColumnItemComponent } from './panel/item/shared.panel.column.item.component';
import { SharedPanelPageAbstract } from './panel/shared.panel.page.abstract';
import { RegisterAskUserDriverComponent } from './register/register-ask-user-driver/register-ask-user-driver.component';
import { RegisterDriversComponent } from './register/register-drivers/register-drivers.component';
import { SharedRegisterFinalComponent } from './register/register-final/shared.register.final.component';
import { RegisterMakeYourselfVisibleComponent } from './register/register-make-yourself-visible/register-make-yourself-visible.component';
import { RegisterPageComponent } from './register/register-page.component';
import { RegisterProductsComponent } from './register/register-products/register-products.component';
import { RegisterCompanyInfoPageComponent } from './register/register-tenant-info/register-tenant-info.component';
import { RegisterUserInfoPageComponent } from './register/register-user-info-page/register-user-info-page.component';
import { SharedRegisterUserInfoComponent } from './register/register-user-info/shared.register.user.info.component';
import { RegisterUserTypeComponent } from './register/register-user-type/register-user-type.component';
import { RegisterWantToWorkWthYouComponent } from './register/register-want-to-work-with-you/register-want-to-work-with-you.component';
import { SharedActiveRoutePhaseComponent } from './route/activeroutephase/shared.active.route.phase.component';
import { SharedActiveRoutes } from './route/activeroutes/shared.active.routes';
import { SharedActiveSlotsItemDriverPlate } from './route/activeslots/item/driverplate/shared.active.slots.item.driverplate';
import { SharedActiveSlotsItemHeader } from './route/activeslots/item/header/shared.active.slots.item.header';
import { SharedActiveSlotsItemPhase } from './route/activeslots/item/phase/shared.active.slots.item.phase';
import { SharedActiveSlotsItemCMR } from './route/activeslots/item/cmr/shared.active.slots.item.cmr';
import { SharedActiveSlotsItem } from './route/activeslots/item/shared.active.slots.item';
import { SharedActiveSlots } from './route/activeslots/shared.active.slots';
import { SharedBannersComponent } from './route/banners/shared.banners.component';
import { SharedRouteShipmentConditionComponent } from './route/condition/shared.route.shipment.condition.component';
import { SharedRouteDelegateCreatePage } from './route/delegate/create/shared.route.delegate.create.page';
import { SharedRouteChildOfDivisionComponent } from './route/divided/child/shared.route.childofdivision.component';
import { SharedRouteDivisionComponent } from './route/divided/division/shared.route.division.component';
import { SharedRouteDivisionInfoComponent } from './route/divided/info/shared.route.division.info.component';
import { SharedRouteItemWithChildsComponent } from './route/divided/item/shared.route.item.withchilds.component';
import { SharedDriverPlateComponent } from './route/driverplate/shared.driverplate.component';
import { SharedRouteExternalDriversComponent } from './route/externaldrivers/shared.route.externaldrivers.component';
import { SharedRouteExworksComponent } from './route/exworks/shared.route.exworks.component';
import { SharedRouteGroupChildListComponent } from './route/group/list/shared.route.group.child.list.component';
import { SharedRouteGroupComponent } from './route/group/shared.route.group.component';
import { SharedRouteInBoundCreatePage } from './route/inbounds/create/shared.route.inbound.create.page';
import { SharedRouteInfoComponent } from './route/info/shared.route.info.component';
import { SharedRouteItemListComponent } from './route/itemlist/shared.route.item.list.component';
import { SharedRouteItemEditMaintenanceComponent } from './route/maintenance/edit/shared.route.item.edit.maintenance.component';
import { SharedRouteItemLogMaintenanceComponent } from './route/maintenance/log/shared.route.item.log.maintenance.component';
import { SharedUserLogInfoComponent } from './route/maintenance/log/userinfo/shared.user.log.info.component';
import { SharedRouteItemMaintenanceComponent } from './route/maintenance/shared.route.item.maintenance.component';
import { SharedSlotLogComponent } from './route/maintenance/slots/shared.slot.log.component';
import { SharedRouteMapInfoComponent } from './route/map-info/shared.route.map-info.component';
import { SharedRouteNonacceptanceCreatePage } from './route/nonacceptance/create/shared.route.nonacceptance.create.page';
import { SharedRouteOutBoundCreatePage } from './route/outbounds/create/shared.route.outbound.create.page';
import { SharedRouteReverseLogisticsCreatePage } from './route/reverselogistics/create/shared.route.reverselogistics.create.page';
import { SharedRoutePartnerForcedBlockedComponent } from './route/partnerselector/forcedblocked/shared.route.partnerforcedblocked.component';
import { SharedRoutePartnerSelectorComponent } from './route/partnerselector/shared.route.partnerselector.component';
import { SharedBlameSelectorComponent } from './route/phase/blameselector/shared.blame.selector.component';
import { SharedPhaseChangeDatesItem } from './route/phase/changedates/phase/shared.phase.changedates.item';
import { SharedPhaseChangeDatesComponent } from './route/phase/changedates/shared.phase.changedates.component';
import { SharedPhaseItemMaintenanceComponent } from './route/phase/edit/shared.phase.item.maintenance.component';
import { SharedPhaseLoadConsoleManagementComponent } from './route/phase/loadconsolemanagement/shared.phase.loadconsole.management.component';
import { SharedStatusTimeComponent } from './route/phase/statustime/shared.statustime.component';
import { SharedPhaseTimesComponent } from './route/phase/times/shared.phase.times.component';
import { SharedRouteProgressComponent } from './route/progress/shared.route.progress.component';
import { SharedRouteReturnBaseCreatePage } from './route/returns/create/shared.route.return.base.create.page';
import { SharedRouteReturnCreatePage } from './route/returns/create/shared.route.return.create.page';
import { SharedShipmentMultiAddressComponent } from './route/shipment/address/shared.shipment.multi.address.component';
import { SharedShipmentDeliveryLineComponent } from './route/shipment/delivery/line/shared.shipment.delivery.line.component';
import { SharedPackageLineComponent } from './route/shipment/delivery/package/line/shared.package.line.component';
import { SharedPackageComponent } from './route/shipment/delivery/package/shared.package.component';
import { SharedShipmentDeliveryComponent } from './route/shipment/delivery/shared.shipment.delivery.component';
import { SharedESchenkerComponent } from './route/shipment/eschenker/shared.eschenker.component';
import { SharedShipmentInfoComponent } from './route/shipment/info/shared.shipment.info.component';
import { SharedShipmentItemEditMaintenanceComponent } from './route/shipment/maintenance/edit/shared.shipment.item.edit.maintenance.component';
import { SharedShipmentItemConditionsMaintenanceComponent } from './route/shipment/maintenance/conditions/shared.shipment.item.conditions.maintenance.component';
import { SharedShipmentItemConditionEditItemComponent } from './route/shipment/maintenance/conditions/edititem/shared.shipment.item.condition.edititem.component';
import { SharedShipmentItemFormMaintenanceComponent } from './route/shipment/maintenance/form/shared.shipment.item.form.maintenance.component';
import { SharedShipmentItemMaintenanceComponent } from './route/shipment/maintenance/shared.shipment.item.maintenance.component';
import { SharedShipmentItemComponent } from './route/shipment/shared.shipment.item.component';
import { SharedSlotViewerComponent } from './route/slot/shared.slot.viewer.component';
import { SharedTelemetry } from './route/telemetry/shared.telemetry';
import { SharedTelemetryTable } from './route/telemetry/table/shared.telemetry.table';
import { SharedPhaseTimeLineComponent } from './route/timeline/shared.phase.timeline.component';
import { SharedPhaseSlotPlanningComponent } from './slots/phase-slot-planning/shared.phase.slot.planning.component';
import { SharedPhaseSlotComponent } from './slots/phase/shared.phase.slot.component';
import { SharedSlotPickerComponent } from './slots/slot-picker/shared.slot.picker.component';
import { SharedSlotPlanningComponent } from './slots/slot-planning/shared.slot.planning.component';
import { SharedSlotsPlanningComponent } from './slots/slots-planning/shared.slots.planning.component';
import { SharedSlotsPlanningHeaderComponent } from './slots/slots-planning/header/shared.slots.planning.header.component';
import { SharedStatusTableAddressComponent } from './statistics/statustableaddress/shared.statustable.address.component';
import { SharedBillingInfoComponent } from './system/billinginfo/shared.billinginfo.component';
import { SharedInvoiceComponent } from './system/billinginfo/shared.invoice.component';
import { SharedPaymentMethodComponent } from './system/paymentmethod/shared.paymentmethod.component';
import { SharedProductsComponent } from './system/product/shared.products.component';
import { SharedTenantInfoComponent } from './system/tenantinfo/shared.tenantinfo.component';
import { TestAlertPageComponent } from './test/test-alert-page/test-alert-page.component';
import { TestAvatarPageComponent } from './test/test-avatar-page/test-avatar-page.component';
import { TestComunicationsPageComponent } from './test/test-comunications-page/test-comunications-page.component';
import { TestDataTypesPageComponent } from './test/test-datatypes-page/test-datatypes-page.component';
import { SharedUserInfoCard } from './user/card/shared.user.info.card';
import { IsEmptyDirective } from './core/basics/is-empty.directive';
import { SharedRouteItemEditAdditionalDataComponent } from './route/maintenance/edit/additionalinfo/shared.route.item.edit.additionaldata.component';
import { SharedRouteExternalDriversEditComponent } from './route/externaldrivers/edit/shared.route.externaldrivers.edit.component';
import { SharedAsideFactoryInvokerUserLog } from './aside/shared.aside.factory.invoker.userlog';
import { SharedTelemetryItem } from './route/telemetry/item/shared.telemetry.item';
import { SharedRoutePartCMRCardComponent } from './route/cmr/card/shared.route.part.cmr.card.component';
import { SharedRouteCMRDeliveryLineComponent } from './route/cmr/edit/line/shared.route.cmr.delivery.line.component';
import { SharedRoutePartCMREditComponent } from './route/cmr/edit/shared.route.part.cmr.edit.component';
import { SharedRoutePartCMRMaintenanceComponent } from './route/cmr/shared.route.part.cmr.maintenance.component';
import { SharedRouteCMRDeliveryOriginLineComponent } from './route/cmr/edit/line/shared.route.cmr.delivery.origin.line.component';
import { SharedRoutePartCMREditAdditionalDataComponent } from './route/cmr/edit/additionaldata/shared.route.part.cmr.edit.additionaldata.component';
import { SharedRoutePartCMRQRComponent } from './route/cmr/qr/shared.route.part.cmr.qr.component';
import { SharedRoutePartCMRDigitalDocumentComponent } from './route/cmr/digitaldocument/shared.route.part.cmr.digitaldocument.component';
import { SharedRouteDividedComponent } from './route/divided/shared.route.divided.component';
import { SharedRoutePartCMRListComponent } from './route/cmr/list/shared.route.part.cmr.list.component';
import { SharedUpdateIncotermDialogComponent } from './route/cmr/digitaldocument/updateincotermdialog/shared.updateincotermdialog.component';
import { UpdateIncotermDialogDialogComponent } from './route/cmr/digitaldocument/updateincotermdialog/dialog/updateincotermdialog.dialog.component';
import { SharedUpdatePartnerDialogComponent } from './route/cmr/digitaldocument/updatepartnerdialog/shared.updatepartnerdialog.component';
import { UpdatePartnerDialogDialogComponent } from './route/cmr/digitaldocument/updatepartnerdialog/dialog/updatepartnerdialog.dialog.component';
import { SharedUpdateAddressDialogComponent } from './route/cmr/digitaldocument/updateaddressdialog/shared.updateaddressdialog.component';
import { UpdateAddressDialogDialogComponent } from './route/cmr/digitaldocument/updateaddressdialog/dialog/updateaddressdialog.dialog.component';
import { SharedUpdatePaymentDialogComponent } from './route/cmr/digitaldocument/updatepaymentdialog/shared.updatepaymentdialog.component';
import { UpdatePaymentDialogDialogComponent } from './route/cmr/digitaldocument/updatepaymentdialog/dialog/updatepaymentdialog.dialog.component';
import { SortByLanguagePoints } from '../filters/SortByLanguagePoints';
import { TranslateH, TranslateInPipe, TranslateInParamsPipe } from '../filters/TranslateInPipe';
import { OrderByTruckingRouteDto } from '../filters/OrderByTruckingRouteDto';
import { OrderByTruckingShipmentDto } from '../filters/OrderByTruckingShipmentDto';
import { SharedTruckingPackageItem } from './trucking/shipment/package/shared.trucking.package.item';
import { SharedTruckingShipmentDetailComponent } from './trucking/shipment/detail/shared.trucking.shipment.detail.component';
import { SharedTruckingRoutePanelLoadComponent } from './trucking/route/panel/load/shared.trucking.route.panel.load.component';
import { SharedDocumentaryReviewItemECMRComponent } from './documentaryreview/item/cmr/item/shared.documentaryreview.item.ecmr.component';
import { SharedDocumentaryReviewListECMRComponent } from './documentaryreview/item/cmr/shared.documentaryreview.list.ecmr.component';
import { SharedSignCMRMaintenanceComponent } from './route/cmr/sign/shared.sign.cmr.maintenance.component';
import { SharedSignCMRLogComponent } from './route/cmr/sign/shared.sign.cmr.log.component';
import { SharedTotemComponent } from './totem/shared.totem.component';
import { SharedTotemIdentificationComponent } from './totem/identification/shared.totem.identification.component';
import { SharedQRScannerComponent } from './core/qrscanner/shared.qrscanner.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { SharedTotemLoadingComponent } from './totem/loading/shared.totem.loading.component';
import { SharedTotemRouteComponent } from './totem/route/shared.totem.route.component';
import { SharedTotemIdVerificationComponent } from './totem/idverification/shared.totem.idverification.component';
import { SharedSecretComponent } from './core/basics/secret/shared.secret.component';
import { SharedTotemAddMoreComponent } from './totem/addmore/shared.totem.addmore.component';
import { SharedSignComponent } from './sign/shared.sign.component';
import { SharedPhaseCMRComponent } from './route/phase/cmr/shared.phase.cmr.component';
import { SharedAccessControlStatusComponent } from './accesscontrol/status/shared.accesscontrol.status.component';
import { SharedExworkPhaseCMRQRComponent } from './exwork/cmr/phaseqr/shared.exwork.phase.cmr.qr.component';
import { SharedExworkPhaseCMRsQRComponent } from './exwork/cmr/phaseqr/cmrsqr/shared.exwork.phase.cmrs.qr.component';
import { SharedTotemEnterDataConfirmationComponent } from './totem/dataconfirmation/enter/shared.totem.enter.dataconfirmation.component';
import { SharedTotemSlotComponent } from './totem/slot/shared.totem.slot.component';
import { SharedTotemSlotPickerComponent } from './totem/slot/picker/shared.totem.slotpicker.component';
import { SharedTotemSignComponent } from './totem/sign/shared.totem.sign.component';
import { SharedTotemExitComponent } from './totem/exit/shared.totem.exit.component';
import { SharedTotemEstaDinsComponent } from './totem/estadins/shared.totem.estadins.component';
import { SharedTotemEnterFinishComponent } from './totem/enterfinish/shared.totem.enterfinish.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// If using Moment
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'//'en-GB'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
const CUSTOM_DATE_TIME_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'//'en-GB'
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};


@NgModule({
  declarations: [
    AvatarComponent,
    AddressCalendarDay,
    AddressCalendarMonths,
    SharedAssistedAddressComponent,
    SharedSelectorAddressmanualaddressComponent,
    CalendarDay,
    CalendarMonth,
    CalendarMonths,
    ColorPickerComponent,
    DataHoraDetall,
    DataDetallDiaSetmana,
    DataHoraDetallDiaSetmana,
    DataHoraDetallOnlyDiaSetmana,
    DataHoraMiniFase,
    PartnerDetailComponent,
    PhaseAddressData,
    PercentageFilter,
    PriceFilter,
    QuantityFilter,
    EnterFilter,
    BooleanComponent,
    SharedButtonLinkComponent,
    EntitySelectorComponent,
    IsEmptyDirective,
    FileDragAndDropComponent,
    FilterPipe,
    ExcludeBookingsPipe,
    FilterActiveRoutesPipe,
    FilterRemoveRepeat,
    WithCountPipe,
    SharedBoxesComponent,
    ButtonToggleComponent,
    DatePickerComponent,
    DateHourPickerComponent,
    DateHourViewerComponent,
    DateFilter,
    DateNoYearFilter,
    DateHourFilter,
    DateHourWithSecondsFilter,
    DateHourWithSecondsFilterNoTz,
    DateHourNoTzFilter,
    DateHourOnlyTimeZoneFilter,
    DateHouronlydateobserver,
    DateHouronlyhourobserver,
    DateHourOnlyDateNoTzFilter,
    DateHourOnlyHourNoTzFilter,
    DateHourOnlyDateFilter,
    DateHourOnlyHourFilter,
    DateHourNoSecondsNoTzFilter,
    DateHourNoSecondsNoTzObserverFilter,
    DateHourNoSecondsObserverFilter,
    DateHourNoTzObserverFilter,
    DateHourNoSecondsReactiveFilter, DateHourNoSecondsObserverReactiveFilter,
    DateHourIntervalFilter,
    DateHourIntervalObserverFilter,
    DateValidator,
    DateHourValidator,
    EnumSelectorComponent,
    SharedHelpPointComponent,
    HelpPointDialogComponent,
    HourFilter,
    HourFilterNotNull,
    HourIntervalFilter,
    HourIntervalTotalFilter,
    HourIntervalsFilter,
    HourIntervalsFilterHTML,
    HourPickerComponent,
    HourValidator,
    HourIntervalsPickerComponent,
    HourIntervalPickerComponent,
    HourIntervalsValidator,
    HourIntervalValidator,
    HTranslateFilter,
    InputComponent,
    LabelDateHourComponent,
    MapsSearchComponent,
    SharedMenuBurguerComponent,
    SharedMenuOptionBurguerComponent,
    SharedSignatureComponent,
    SharedQRScannerComponent,
    SelectComponent,
    CheckboxComponent,
    KeysPipe,
    OrderByAccessControlGatePlateDto,
    OrderByMenuOptionDto,
    OrderByRouteCardDto,
    OrderByTruckingRouteDto,
    OrderByTruckingShipmentDto,
    OrderClientProviderViewShipmentDto,
    RegisterCompanyInfoPageComponent,
    RegisterUserInfoPageComponent,
    RegisterProductsComponent,
    SharedAccessControlStatusComponent,
    SharedAccessControlPlateRouteInfoComponent,
    SharedActiveRoutes,
    SharedBannersComponent,
    SharedRouteBookingComponent,
    SharedRouteChangeStatusComponent,
    SharedRouteChangeConditionsComponent,
    SharedRouteChangeTagsComponent,
    SharedActiveSlotsItemDriverPlate,
    SharedActiveSlotsItemHeader,
    SharedActiveSlotsItemPhase,
    SharedActiveSlotsItemCMR,
    SharedActiveSlotsItem,
    SharedActiveSlots,
    SharedManagementModelChatComponent,
    SharedManagementModelRequirementItemComponent,
    SharedManagementModelRequirementsComponent,
    SharedManagementModelRequirementsLogComponent,
    SharedManagementModelInfoComponent,
    SharedManagementModelItemComponent,
    SharedDocumentaryReviewItemECMRComponent,
    SharedDocumentaryReviewDocumentsComponent,
    SharedDocumentaryReviewItemDocumentsComponent,
    SharedDocumentaryReviewListECMRComponent,
    SharedDocumentaryReviewRouteItemComponent,
    SharedDocumentaryReviewRoutes,
    SharedRouteReturnBaseCreatePage,
    SharedRouteReturnCreatePage,
    SharedRouteInBoundCreatePage,
    SharedRouteOutBoundCreatePage,
    SharedRouteReverseLogisticsCreatePage,
    SharedRouteNonacceptanceCreatePage,
    SharedRouteDelegateCreatePage,
    SharedRouteDivisionComponent,
    SharedRouteDividedComponent,
    SharedRouteChildOfDivisionComponent,
    SharedRouteDivisionInfoComponent,
    SharedRouteItemWithChildsComponent,
    SharedRouteGroupComponent,
    SharedRouteGroupPanelComponent,
    SharedRouteGroupPanelMaintenanceComponent,
    SharedRouteGroupChildListComponent,
    SharedRegisterUserInfoComponent,
    SharedRegisterFinalComponent,
    SharedBillTypeCreatePage,
    RegisterUserTypeComponent,
    RegisterWantToWorkWthYouComponent,
    RegisterMakeYourselfVisibleComponent,
    RegisterAskUserDriverComponent,
    RegisterDriversComponent,
    RegisterPageComponent,
    SharedBookingPageComponent,
    SharedBookingCardComponent,
    SharedBookingPartnerSelectorComponent,
    SharedRoutePartnerForcedBlockedComponent,
    SharedRoutePartnerSelectorComponent,
    SharedRouteExworksEditComponent,
    SharedRouteExternalDriversEditComponent,
    SharedRouteExworksComponent,
    SharedRouteExternalDriversComponent,
    SharedBookingRouteSelectorComponent,
    SharedBookingFormComponent,
    SharedContainerNumberComponent,
    SharedPartnerInfoCardComponent,
    SharedChartComponent,
    SharedChat,
    SharedChatItem,
    SharedChatMessage,
    SharedChats,
    SharedChatsIcon,
    SharedChatAvatar,
    SharedCustomTableFieldComponent,
    SharedCustomTableColumnComponent,
    SharedCustomTableComponent,
    SharedDocumentViewComponent,
    SharedFormQuestionAnswerShipmentItem,
    SharedFormQuestionsAnswersShipment,
    SharedSimpleTableComponent,
    SharedTableComponent,
    SharedUserInfoCard,
    SharedUserLogInfoComponent,
    SharedPhaseSlotComponent,
    SharedPhaseSlotPlanningComponent,
    SharedPhaseChangeDatesItem,
    SharedPhaseChangeDatesComponent,
    SharedPhaseChangeStrongAddressComponent,
    SharedPhaseCMRComponent,
    SharedImageComponent,
    SharedImagesComponent,
    SharedInvoiceComponent,
    SharedJourneyDetailComponent,
    SharedLabelsComponent,
    SharedLiteComponent,
    SharedLiteItemComponent,
    SharedAddressAmbitSelectorComponent,
    SharedAddressAccessControlSelectorComponent,
    SharedMapAddressComponent,
    SharedMapComponent,
    SharedMapInfoWindowComponent,
    SharedMapRouteComponent,
    SharedPanelElementLogComponent,
    SharedPanelElementInfoComponent,
    SharedPanelColumnItemComponent,
    SharedPanelPageAbstract,
    SharedReserveOutOfHoursBox,
    SharedSlotPickerComponent,
    SharedSlotPlanningComponent,
    SharedSlotsPlanningComponent,
    SharedSlotsPlanningHeaderComponent,
    SharedExworkComponent,
    SharedExworkRouteBookingComponent,
    SharedExworkRouteGroup,
    SharedExworkSlotPickerComponent,
    SharedExworkPhaseCardComponent,
    SharedExworkPhaseCMRsQRComponent,
    SharedExworkPhaseCMRQRComponent,
    SharedExworkRoutePartCMRDigitalDocumentComponent,
    SharedDockPickerComponent,
    SharedDockPlanningComponent,
    SharedDocksPlanningComponent,
    SharedStatusTableAddressComponent,
    SharedSupervisionComponent,
    SharedTypologyTimeBox,
    SafePipe,
    SortByPipe,
    SortByLanguagePoints,
    SortByIntervalProperty,
    SortLoadConsoleSlotPhaseDto,
    TranslateInPipe,
    TranslateInParamsPipe,
    TranslateH,
    TagsChipsComponent,
    TagsValidator,
    TelemetryDoubleFilter,
    TextAreaComponent,
    TimeCaretComponent,
    TimeDaysFilter,
    TimeFilter,
    TimeFilterNotNull,
    MillisecondsToMinutesSecondsMillis,
    TimePickerComponent,
    TimeStampPipe,
    TimeStampPipeTz,
    TimeValidator,
    TriStateComponent,
    TestDataTypesPageComponent,
    TestAlertPageComponent,
    TestAvatarPageComponent,
    TestComunicationsPageComponent,
    SharedActionsComponent,
    SharedFilterComponent,
    SharedOptionsComponent,
    OrderActionDtoFilter,
    OrderFilterDtoFilter,
    avoidActionsFilter,
    OrderOptionDtoFilter,
    avoidOptionsFilter,
    onlyOptionsFilter,
    SharedProductsComponent,
    SharedTagsToggleComponent,
    SharedTagsStripComponent,
    SortPermission,
    FilterFromMeToYouLinks,
    SharedGeneralStatusContainerComponent,
    SharedLoadConsolePhaseInfoComponent,
    SharedClientProviderViewComponent,
    SharedClientProviderViewShipmentCardComponent,
    SharedRouteInfoComponent,
    SharedActiveRoutePhaseComponent,
    SharedDriverPlateComponent,
    SharedSlotViewerComponent,
    SharedRouteMapInfoComponent,
    SharedRouteItemComponent,
    SharedRouteStripComponent,
    SharedRouteItemListComponent,
    SharedRouteProgressComponent,
    SharedRouteShipmentConditionComponent,
    SharedRouteItemMaintenanceComponent,
    SharedRouteItemEditAdditionalDataComponent,
    SharedRouteItemEditMaintenanceComponent,
    SharedRouteItemLogMaintenanceComponent,
    SharedSlotLogComponent,
    SharedTelemetryTable,
    SharedTelemetry,
    SharedTelemetryItem,
    SharedSignComponent,
    SharedTotemComponent,
    SharedTotemIdentificationComponent,
    SharedTotemEstaDinsComponent,
    SharedTotemEnterFinishComponent,
    SharedTotemEnterDataConfirmationComponent,
    SharedTotemIdVerificationComponent,
    SharedTotemAddMoreComponent,
    SharedTotemRouteComponent,
    SharedTotemLoadingComponent,
    SharedTotemSlotPickerComponent,
    SharedTotemSlotComponent,
    SharedTotemSignComponent,
    SharedTotemExitComponent,
    SharedPhaseItemComponent,
    SharedPhaseLoadConsoleManagementComponent,
    SharedPhaseTimeLineComponent,
    SharedPhaseItemMaintenanceComponent,
    SharedBlameSelectorComponent,
    SharedPhaseTimesComponent,
    SharedStatusTimeComponent,
    SharedESchenkerComponent,
    SharedCopyClipBoard,
    SharedSecretComponent,
    SharedSaveButton,
    SharedShipmentDeliveryComponent,
    SharedShipmentDeliveryLineComponent,
    SharedPackageComponent,
    SharedPackageLineComponent,
    SharedRoutePartCMRCardComponent,
    SharedRoutePartCMRListComponent,
    SharedRouteCMRDeliveryLineComponent,
    SharedRoutePartCMREditComponent,
    SharedShipmentCMRMaintenanceComponent,
    SharedSignCMRMaintenanceComponent,
    SharedSignCMRLogComponent,
    SharedRoutePartCMRMaintenanceComponent,
    SharedRoutePartCMREditAdditionalDataComponent,
    SharedCMREventsListComponent,
    SharedRoutePartCMREditEventsComponent,
    SharedRoutePartCMRQRComponent,
    SharedPhaseCMRQRComponent,
    SharedRoutePartCMRDigitalDocumentComponent,
    SharedShipmentCMREditAdditionalDataComponent,
    SharedShipmentCMREditEventsComponent,
    SharedRouteCMRDeliveryLineComponent,
    SharedRouteCMRDeliveryOriginLineComponent,
    SharedAsideFactoryInvokerUserLog,
    SharedShipmentInfoComponent,
    SharedShipmentItemComponent,
    SharedShipmentItemEditMaintenanceComponent,
    SharedShipmentItemConditionsMaintenanceComponent,
    SharedShipmentItemConditionEditItemComponent,
    SharedShipmentItemFormMaintenanceComponent,
    SharedShipmentItemMaintenanceComponent,
    SharedShipmentMultiAddressComponent,
    SharedOpenDocumentComponent,
    SharedIFrameItemDialog,
    SharedImageSliderDialog,
    SharedImageSliderMobileDialog,
    SharedStateComponent,
    SharedGeneralStatusViewerComponent,
    SharedBookingStateComponent,
    SharedStateUserComponent,
    SharedTruckingRoutePanelLoadComponent,
    SharedTruckingRoutePanelComponent,
    SharedTruckingRouteCard,
    SharedTruckingRouteStripComponent,
    SharedTruckingRoutes,
    SharedTruckingPackageItem,
    SharedTruckingShipmentCard,
    SharedTruckingShipmentDetailComponent,
    SharedTruckingShipmentStripComponent,
    SharedTruckingShipments,
    SharedTruckingComponent,
    DataDetall,
    DiaDataDetall,
    MiniDia,
    IntervalFilter,
    SharedTenantInfoComponent,
    SharedBillingInfoComponent,
    SharedPaymentMethodComponent,
    SortDocumentType,
    UTCFilter,
    SharedWebCamComponent,
    TagFilter,
    TriStateFilter,
    NumberFilter,
    SharedConfirmationDialogComponent,
    ConfirmationDialogDialogComponent,
    SharedUpdateIncotermDialogComponent,
    UpdateIncotermDialogDialogComponent,
    SharedUpdatePartnerDialogComponent,
    UpdatePartnerDialogDialogComponent,
    SharedUpdateAddressDialogComponent,
    UpdateAddressDialogDialogComponent,
    SharedUpdatePaymentDialogComponent,
    UpdatePaymentDialogDialogComponent,
    TrackPageComponent,
    TrackContacteDialog,
    TrackDeliveryDialog,
    NotrackPageComponent,
    SharedBannersComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents:
    [
      HelpPointDialogComponent,
      SharedIFrameItemDialog,
      SharedImageSliderDialog,
      SharedImageSliderMobileDialog
    ],
  imports: [
    GoogleMapsModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    TextFieldModule,
    ReactiveFormsModule,
    ColorPickerModule,
    ScrollingModule,
    NgImageSliderModule,
    InfiniteScrollModule,
    ChartsModule,
    MaterialAppModule,
    WebcamModule,
    ScrollingModule,
    NgxScannerQrcodeModule
  ],
  providers: [
    HttpClient,
    DateHourFilter,
    DateHourWithSecondsFilter,
    DateHourWithSecondsFilterNoTz,
    DateHourNoTzFilter,
    DateHourOnlyTimeZoneFilter,
    DateHouronlydateobserver,
    DateHouronlyhourobserver,
    DateHourNoSecondsNoTzFilter,
    DateHourNoSecondsNoTzObserverFilter,
    DateHourNoSecondsObserverFilter,
    DateHourNoTzObserverFilter,
    DateHourNoSecondsReactiveFilter, DateHourNoSecondsObserverReactiveFilter,
    DateHourOnlyDateNoTzFilter,
    DateHourOnlyHourNoTzFilter,
    DateHourIntervalFilter,
    DateHourIntervalObserverFilter,
    TimeFilter,
    TranslateInPipe,
    TranslateInParamsPipe,
    TranslateH,
    File,
    FileOpener,
    Camera,
    CameraService,
    FileService,
    TranslateService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS }
  ],
  exports: [
    AvatarComponent,
    AddressCalendarDay,
    AddressCalendarMonths,
    SharedAssistedAddressComponent,
    SharedSelectorAddressmanualaddressComponent,
    CalendarDay,
    CalendarMonth,
    CalendarMonths,
    ColorPickerComponent,
    DataHoraDetall,
    DataDetallDiaSetmana,
    DataHoraDetallDiaSetmana,
    DataHoraDetallOnlyDiaSetmana,
    DataHoraMiniFase,
    PartnerDetailComponent,
    PhaseAddressData,
    PercentageFilter,
    PriceFilter,
    QuantityFilter,
    EnterFilter,
    BooleanComponent,
    SharedButtonLinkComponent,
    EntitySelectorComponent,
    IsEmptyDirective,
    FileDragAndDropComponent,
    FilterPipe,
    ExcludeBookingsPipe,
    FilterActiveRoutesPipe,
    FilterRemoveRepeat,
    WithCountPipe,
    ButtonToggleComponent,
    DatePickerComponent,
    DateHourPickerComponent,
    DateHourViewerComponent,
    DateFilter,
    DateNoYearFilter,
    DateHourFilter,
    DateHourWithSecondsFilter,
    DateHourWithSecondsFilterNoTz,
    DateHourNoTzFilter,
    DateHourOnlyTimeZoneFilter,
    DateHouronlydateobserver,
    DateHouronlyhourobserver,
    DateHourOnlyDateNoTzFilter,
    DateHourOnlyHourNoTzFilter,
    DateHourOnlyDateFilter,
    DateHourOnlyHourFilter,
    DateHourNoSecondsNoTzFilter,
    DateHourNoSecondsNoTzObserverFilter,
    DateHourNoSecondsObserverFilter,
    DateHourNoTzObserverFilter,
    DateHourNoSecondsReactiveFilter, DateHourNoSecondsObserverReactiveFilter,
    DateHourIntervalFilter,
    DateHourIntervalObserverFilter,
    DateValidator,
    DateHourValidator,
    EnumSelectorComponent,
    SharedHelpPointComponent,
    HelpPointDialogComponent,
    HourFilter,
    HourFilterNotNull,
    HourIntervalFilter,
    HourIntervalTotalFilter,
    HourIntervalsFilter,
    HourIntervalsFilterHTML,
    HourPickerComponent,
    HourValidator,
    HourIntervalsPickerComponent,
    HourIntervalPickerComponent,
    HourIntervalsValidator,
    HourIntervalValidator,
    HTranslateFilter,
    InputComponent,
    LabelDateHourComponent,
    MapsSearchComponent,
    SharedMenuBurguerComponent,
    SharedMenuOptionBurguerComponent,
    SharedSignatureComponent,
    SharedQRScannerComponent,
    SelectComponent,
    CheckboxComponent,
    KeysPipe,
    OrderByAccessControlGatePlateDto,
    OrderByMenuOptionDto,
    OrderByRouteCardDto,
    OrderByTruckingRouteDto,
    OrderByTruckingShipmentDto,
    OrderClientProviderViewShipmentDto,
    RegisterCompanyInfoPageComponent,
    RegisterUserInfoPageComponent,
    RegisterProductsComponent,
    RegisterUserTypeComponent,
    RegisterWantToWorkWthYouComponent,
    RegisterMakeYourselfVisibleComponent,
    RegisterAskUserDriverComponent,
    RegisterDriversComponent,
    RegisterPageComponent,
    SharedAccessControlStatusComponent,
    SharedAccessControlPlateRouteInfoComponent,
    SharedActiveRoutes,
    SharedBannersComponent,
    SharedActiveSlotsItemDriverPlate,
    SharedActiveSlotsItemHeader,
    SharedActiveSlotsItemPhase,
    SharedActiveSlotsItemCMR,
    SharedActiveSlotsItem,
    SharedActiveSlots,
    SharedManagementModelChatComponent,
    SharedManagementModelRequirementItemComponent,
    SharedManagementModelRequirementsComponent,
    SharedManagementModelRequirementsLogComponent,
    SharedManagementModelInfoComponent,
    SharedManagementModelItemComponent,
    SharedDocumentaryReviewItemECMRComponent,
    SharedDocumentaryReviewDocumentsComponent,
    SharedDocumentaryReviewItemDocumentsComponent,
    SharedDocumentaryReviewListECMRComponent,
    SharedDocumentaryReviewRouteItemComponent,
    SharedDocumentaryReviewRoutes,
    SharedRouteReturnBaseCreatePage,
    SharedRouteReturnCreatePage,
    SharedRouteInBoundCreatePage,
    SharedRouteOutBoundCreatePage,
    SharedRouteReverseLogisticsCreatePage,
    SharedRouteNonacceptanceCreatePage,
    SharedRouteDelegateCreatePage,
    SharedRouteDivisionComponent,
    SharedRouteDividedComponent,
    SharedRouteChildOfDivisionComponent,
    SharedRouteDivisionInfoComponent,
    SharedRouteGroupComponent,
    SharedRouteGroupPanelComponent,
    SharedRouteGroupPanelMaintenanceComponent,
    SharedRouteGroupChildListComponent,
    SharedRouteItemWithChildsComponent,
    SharedBillTypeCreatePage,
    SharedBookingPageComponent,
    SharedBookingCardComponent,
    SharedBookingPartnerSelectorComponent,
    SharedRoutePartnerForcedBlockedComponent,
    SharedRoutePartnerSelectorComponent,
    SharedRouteExworksEditComponent,
    SharedRouteExternalDriversEditComponent,
    SharedRouteExworksComponent,
    SharedRouteExternalDriversComponent,
    SharedBookingRouteSelectorComponent,
    SharedBookingFormComponent,
    SharedContainerNumberComponent,
    SharedPartnerInfoCardComponent,
    SharedBoxesComponent,
    SharedChartComponent,
    SharedChat,
    SharedChatItem,
    SharedChatMessage,
    SharedChats,
    SharedChatsIcon,
    SharedChatAvatar,
    SharedCustomTableFieldComponent,
    SharedCustomTableColumnComponent,
    SharedCustomTableComponent,
    SharedDocumentViewComponent,
    SharedFormQuestionAnswerShipmentItem,
    SharedFormQuestionsAnswersShipment,
    SharedSimpleTableComponent,
    SharedTableComponent,
    SharedUserInfoCard,
    SharedUserLogInfoComponent,
    SharedPhaseSlotComponent,
    SharedPhaseSlotPlanningComponent,
    SharedPhaseChangeDatesItem,
    SharedPhaseChangeDatesComponent,
    SharedPhaseChangeStrongAddressComponent,
    SharedPhaseCMRComponent,
    SharedLabelsComponent,
    SharedImageComponent,
    SharedImagesComponent,
    SharedInvoiceComponent,
    SharedJourneyDetailComponent,
    SharedLiteComponent,
    SharedLiteItemComponent,
    SharedAddressAmbitSelectorComponent,
    SharedAddressAccessControlSelectorComponent,
    SharedMapAddressComponent,
    SharedMapComponent,
    SharedMapInfoWindowComponent,
    SharedMapRouteComponent,
    SharedPanelElementLogComponent,
    SharedPanelElementInfoComponent,
    SharedPanelColumnItemComponent,
    SharedPanelPageAbstract,
    SharedReserveOutOfHoursBox,
    SharedSlotPickerComponent,
    SharedSlotPlanningComponent,
    SharedSlotsPlanningComponent,
    SharedSlotsPlanningHeaderComponent,
    SharedDockPickerComponent,
    SharedExworkComponent,
    SharedExworkRouteBookingComponent,
    SharedExworkRouteGroup,
    SharedExworkSlotPickerComponent,
    SharedExworkPhaseCardComponent,
    SharedExworkPhaseCMRsQRComponent,
    SharedExworkPhaseCMRQRComponent,
    SharedExworkRoutePartCMRDigitalDocumentComponent,
    SharedDockPlanningComponent,
    SharedDocksPlanningComponent,
    SharedStatusTableAddressComponent,
    SharedSupervisionComponent,
    SharedTypologyTimeBox,
    SafePipe,
    SortByPipe,
    SortByLanguagePoints,
    SortByIntervalProperty,
    SortLoadConsoleSlotPhaseDto,
    TranslateInPipe,
    TranslateInParamsPipe,
    TranslateH,
    TagsChipsComponent,
    TelemetryDoubleFilter,
    TextAreaComponent,
    TimeCaretComponent,
    TimeDaysFilter,
    TimeFilter,
    TimeFilterNotNull,
    MillisecondsToMinutesSecondsMillis,
    TimePickerComponent,
    TimeStampPipe,
    TimeStampPipeTz,
    TimeValidator,
    TriStateComponent,
    TestDataTypesPageComponent,
    TestAlertPageComponent,
    TestAvatarPageComponent,
    TestComunicationsPageComponent,
    SharedActionsComponent,
    SharedFilterComponent,
    SharedOptionsComponent,
    OrderActionDtoFilter,
    OrderFilterDtoFilter,
    avoidActionsFilter,
    OrderOptionDtoFilter,
    avoidOptionsFilter,
    onlyOptionsFilter,
    SharedProductsComponent,
    SharedTagsToggleComponent,
    SharedTagsStripComponent,
    SortPermission,
    FilterFromMeToYouLinks,
    ScrollingModule,
    SharedGeneralStatusContainerComponent,
    SharedLoadConsolePhaseInfoComponent,
    SharedClientProviderViewComponent,
    SharedClientProviderViewShipmentCardComponent,
    SharedRouteInfoComponent,
    SharedActiveRoutePhaseComponent,
    SharedDriverPlateComponent,
    SharedSlotViewerComponent,
    SharedRouteBookingComponent,
    SharedRouteMapInfoComponent,
    SharedRouteItemComponent,
    SharedRouteStripComponent,
    SharedRouteItemListComponent,
    SharedRouteProgressComponent,
    SharedRouteShipmentConditionComponent,
    SharedRouteItemMaintenanceComponent,
    SharedRouteItemEditAdditionalDataComponent,
    SharedRouteItemEditMaintenanceComponent,
    SharedRouteItemLogMaintenanceComponent,
    SharedRouteChangeConditionsComponent,
    SharedRouteChangeStatusComponent,
    SharedRouteChangeTagsComponent,
    SharedSlotLogComponent,
    SharedTelemetryTable,
    SharedTelemetry,
    SharedTelemetryItem,
    SharedSignComponent,
    SharedTotemComponent,
    SharedTotemIdentificationComponent,
    SharedTotemEstaDinsComponent,
    SharedTotemEnterFinishComponent,
    SharedTotemEnterDataConfirmationComponent,
    SharedTotemIdVerificationComponent,
    SharedTotemAddMoreComponent,
    SharedTotemRouteComponent,
    SharedTotemLoadingComponent,
    SharedTotemSlotPickerComponent,
    SharedTotemSlotComponent,
    SharedTotemSignComponent,
    SharedTotemExitComponent,
    SharedPhaseItemComponent,
    SharedPhaseLoadConsoleManagementComponent,
    SharedPhaseTimeLineComponent,
    SharedPhaseItemMaintenanceComponent,
    SharedPhaseTimesComponent,
    SharedStatusTimeComponent,
    SharedESchenkerComponent,
    SharedBlameSelectorComponent,
    SharedCopyClipBoard,
    SharedSecretComponent,
    SharedSaveButton,
    SharedShipmentDeliveryComponent,
    SharedShipmentDeliveryLineComponent,
    SharedPackageComponent,
    SharedPackageLineComponent,
    SharedRoutePartCMRCardComponent,
    SharedRoutePartCMRListComponent,
    SharedRouteCMRDeliveryLineComponent,
    SharedRoutePartCMREditComponent,
    SharedShipmentCMRMaintenanceComponent,
    SharedSignCMRMaintenanceComponent,
    SharedSignCMRLogComponent,
    SharedRoutePartCMRMaintenanceComponent,
    SharedRoutePartCMREditAdditionalDataComponent,
    SharedCMREventsListComponent,
    SharedRoutePartCMREditEventsComponent,
    SharedRoutePartCMRQRComponent,
    SharedPhaseCMRQRComponent,
    SharedRoutePartCMRDigitalDocumentComponent,
    SharedShipmentCMREditAdditionalDataComponent,
    SharedShipmentCMREditEventsComponent,
    SharedRouteCMRDeliveryLineComponent,
    SharedRouteCMRDeliveryOriginLineComponent,
    SharedAsideFactoryInvokerUserLog,
    SharedShipmentInfoComponent,
    SharedShipmentItemComponent,
    SharedShipmentItemEditMaintenanceComponent,
    SharedShipmentItemConditionsMaintenanceComponent,
    SharedShipmentItemConditionEditItemComponent,
    SharedShipmentItemFormMaintenanceComponent,
    SharedShipmentItemMaintenanceComponent,
    SharedShipmentMultiAddressComponent,
    SharedOpenDocumentComponent,
    SharedIFrameItemDialog,
    SharedImageSliderDialog,
    SharedImageSliderMobileDialog,
    SharedStateComponent,
    SharedGeneralStatusViewerComponent,
    SharedBookingStateComponent,
    SharedStateUserComponent,
    SharedTruckingRoutePanelLoadComponent,
    SharedTruckingRoutePanelComponent,
    SharedTruckingRouteCard,
    SharedTruckingRouteStripComponent,
    SharedTruckingRoutes,
    SharedTruckingPackageItem,
    SharedTruckingShipmentCard,
    SharedTruckingShipmentDetailComponent,
    SharedTruckingShipmentStripComponent,
    SharedTruckingShipments,
    SharedTruckingComponent,
    DataDetall,
    DiaDataDetall,
    MiniDia,
    IntervalFilter,
    SharedTenantInfoComponent,
    SharedBillingInfoComponent,
    SharedPaymentMethodComponent,
    SortDocumentType,
    UTCFilter,
    SharedWebCamComponent,
    TagFilter,
    TriStateFilter,
    NumberFilter,
    SharedConfirmationDialogComponent,
    ConfirmationDialogDialogComponent,
    SharedUpdateIncotermDialogComponent,
    UpdateIncotermDialogDialogComponent,
    SharedUpdatePartnerDialogComponent,
    UpdatePartnerDialogDialogComponent,
    SharedUpdateAddressDialogComponent,
    UpdateAddressDialogDialogComponent,
    SharedUpdatePaymentDialogComponent,
    UpdatePaymentDialogDialogComponent,
    TrackPageComponent,
    TrackContacteDialog,
    TrackDeliveryDialog,
    NotrackPageComponent,
    SharedBannersComponent,
  ]
})

export class ComponentsModule { }
