<ng-container *ngIf="totem">
    <div class="page-title">
        {{ 'TOTEMPAGE.SIGN.TITLE' | translateIn:totem.language | async}}
    </div>
    <div class="column-container">
        <div class="column-central">
            <div *ngIf="totem.step==totemSteps.signature" class="block-signature">
                <shared-sign [uuid]="uuid" [agent]="agent" (signedChanged)="onSignedChanged()"></shared-sign>
            </div>
            <div *ngIf="totem.step==totemSteps.signatureEnd" class="block-signature">
                <div class="text goto">
                    {{'TOTEMPAGE.SIGN.SIGNATUREEND.CAPTION'| translateIn:totem.language | async}}
                </div>
            </div>
        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta">
                <button *ngIf="totem.step==totemSteps.signatureEnd" class="button-action" (click)="buttonOk()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{ getButtonActionCode() | translateIn:totem.language | async}}
                    </div>
                    <div class="name">{{ getButtonActionName() | translateIn:totem.language | async}}
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-container>