import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from '../../services/alert/alert.service';
import { LoadingService } from '../../services/loading/loading.service';
import { BaseController } from '../base.controller';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class testController extends BaseController {

    constructor(
        protected http: HttpClient,
        protected cookieService: CookieService,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router);
    }

    testA(): Observable<Array<Boolean>> {
        return this.get('/test/testa')
    }
    testBatchItemClick(batchitemid: number): Observable<Array<Boolean>> {
        return this.get('/test/testbatchitem/' + batchitemid);
    }
    sms(phone: string, message: string): Observable<Array<Boolean>> {
        return this.post('/test/sms/' + phone + "/" + message, {}, 'sms');
    }

    test500() {
        return this.get('/test/test500')
            .subscribe(

        );
    }
    test404() {
        return this.get('/test/test404')
            .subscribe(
        );
    }
    test401() {
        return this.get('/test/test401')
            .subscribe(
        );
    }
    testException() {
        return this.get('/test/exception')
            .subscribe(
        );
    }
}