<div class="header">
    <div class="buttonBack" (click)="onClickBack($event)">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        <i class="fa fa-times" aria-hidden="true"></i>
    </div>
    <shared-active-slots-item-header [route]="selectedRoute"> </shared-active-slots-item-header>
</div>
<ng-container *ngIf="selectedRoute">
    <div *ngIf="selectedRoute.isDivided" class="banner-divided-grouped">
        <div class="icona"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
        <div class="texte">
            {{ 'SHARED.ROUTE.ITEM.BANNER.ISDIVIDED.CAPTION' | translate }}
        </div>

    </div>
    <div *ngIf="selectedRoute.isChildOfGrouped" class="banner-divided-grouped"
        [innerHTML]="'SHARED.ROUTE.ITEM.BANNER.ISCHILDOFGROUPED.CAPTION' | translate:{ agrupationURI: agrupationURI}">
    </div>
    <!--Cos principal, quan no hi ha res seleccionat-->
    <ng-container *ngIf="optionExpanded=='' || optionExpanded==null">
        <div class="block withcontrolat"
            [ngClass]="{'carrierName': selectedRoute.showAskCarrierName, 'carrierLegalIdentification': selectedRoute.showAskCarrierLegalIdentification, 'carrierAddress': selectedRoute.showAskCarrierAddress }"
            *ngIf="optionExpanded == ''">
            <div class="block" *ngIf="!selectedRoute.isChildOfGrouped && !selectedRoute.isDivided">
                <shared-active-slots-item-driverplate [route]="selectedRoute" (refresh)="getData()"
                    [readonly]="readonly" [hideLink]="true">
                </shared-active-slots-item-driverplate>
                <button-link *ngIf="canAssignDriverAndPlates" variant="forward" class="line right"
                    (linkClick)="onClickLinkOption('driverPlate')">{{
                    'SHARED.ACTIVE.SLOTS.ITEM.DRIVERPLATE.FORWARD.CAPTION'
                    | translate}}</button-link>
                <button-link *ngIf="showAssignDriverAndPlates" variant="action" class="line right"
                    (linkClick)="sendToDriver()">{{ 'SHARED.ACTIVE.SLOTS.ITEM.SENDTODRIVER.ACTION.CAPTION' |
                    translate}}</button-link>

            </div>
            <div class="block" *ngIf="algoAFer && !selectedRoute.isChildOfGrouped && !selectedRoute.isDivided">
                <div class="titolslot">
                    {{'SHARED.SLOT.PICKER.SLOTS.CAPTION'| translate}}
                </div>
                <div class="item only-multiple">
                    <div class="item" *ngFor="let phase of _phasesSlots | sortBy:'executionOrder'">
                        <shared-active-slots-item-phase [phase]="phase" (refreshRoute)="getData()"
                            class="no-help showMarker" [hideLink]="true">
                        </shared-active-slots-item-phase>
                        <button-link *ngIf="!readonly && !phase.isSlotBlocked" class="line link right link-exwork"
                            (click)="onPhaseSlot(phase)">{{ 'SHARED.ACTIVE.SLOTS.ITEM.PHASESLOT.FORWARD.CAPTION' |
                            translate}}</button-link>
                        <button-link *ngIf="!readonly && phase.canChangeStrongAddress"
                            class="line link right link-exwork" (click)="onPhaseForcedStrongAddress(phase)">{{
                            'SHARED.ACTIVE.SLOTS.ITEM.PHASEFORCEDSTRONGADDRESS.FORWARD.CAPTION' |
                            translate}}</button-link>
                            
                        <button-link *ngIf="!readonly"
                            class="line link right link-exwork" (click)="onPhaseSignatureCMR(phase)">{{
                            'SHARED.ACTIVE.SLOTS.ITEM.PHASESIGNATURECMR.FORWARD.CAPTION' |
                            translate}}</button-link>
                    </div>
                </div>
            </div>
            <button-link variant="forward" class="line left" *ngIf="selectedRoute.isGrouped"
                (linkClick)="onClickLinkOption('routesGroupShipment')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.ROUTESGROPSHIPMENT.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" *ngIf="selectedRoute.canGrouping"
                (linkClick)="onClickLinkOption('routesGrouping')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.ROUTESGROPING.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" *ngIf="selectedRoute.canDivided"
                (linkClick)="onClickLinkOption('routesDivided')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.ROUTESDIVIDED.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('partners')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.PARTNERS.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('routeLog')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.ROUTELOG.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('routeChat')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.ROUTECHAT.FORWARD.CAPTION' | translate}}</button-link>
            <!--//INICI @COMENTAT_PER_MIGRACIÓ (S'ha de comentar per la migració)-->
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('routeTelemetry')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.ROUTETELEMETRY.FORWARD.CAPTION' | translate}}</button-link>
            <!--//INICI @COMENTAT_PER_MIGRACIÓ (S'ha de comentar per la migració)-->
            <button-link variant="forward" class="line left"
                (linkClick)="onClickLinkOption('routeMap')">{{'SHARED.ACTIVE.SLOTS.ITEM.ROUTEMAP.FORWARD.CAPTION' |
                translate}}</button-link>
            <button-link variant="forward" class="line left"
                *ngIf="selectedRoute.isChildOfGrouped && selectedRoute.routeParentGroupId > 0"
                (linkClick)="onGoParentGrouped()">{{ 'SHARED.ROUTE.ITEM.GOPARENTGROUPED.FORWARD.CAPTION' |
                translate}}</button-link>
            <button-link variant="forward" class="line left" *ngIf="selectedRoute.canPhasesChangeDates"
                (click)="onClickLinkOption('phasesChangeDates')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.PHASESCHANGEDATES.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('modifyBanner')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.MODIFYBANNER.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('changeStatus')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.CHANGESTATUS.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" *ngIf="selectedRoute.canChangeConditions"
                (linkClick)="onClickLinkOption('changeConditions')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.CHANGECONDITIONS.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('changeTags')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.CHANGETAGS.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('editAdditionalData')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.EDITADDITIONALDATA.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('times')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.TIMES.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" *ngIf="selectedRoute.canShowCMR"
                (linkClick)="onClickLinkOption('cmr')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.CMR.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" *ngIf="selectedRoute.canChangeBooking"
                (linkClick)="onClickLinkOption('booking')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.BOOKING.FORWARD.CAPTION' | translate}}</button-link>
            <button-link variant="forward" class="line left" (linkClick)="onClickLinkOption('documentaryreview')">{{
                'SHARED.ACTIVE.SLOTS.ITEM.DOCUMENTARYREVIEW.FORWARD.CAPTION' | translate}}</button-link>
        </div>
    </ng-container>

    <div class="detall" *ngIf="optionExpanded == 'driverPlate'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.DRIVERPLATE.BACK.CAPTION' | translate}}</button-link>
        <shared-active-slots-item-driverplate class="no-help no-btn-close" [route]="selectedRoute"
            (refresh)="goToNormal()" [readonly]="readonly" [autoEdit]="true"></shared-active-slots-item-driverplate>
    </div>

    <div class="detall active-slots-item-partners" *ngIf="optionExpanded == 'partners'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.ROUTEEXWORK.BACK.CAPTION' | translate}}</button-link>
        <shared-route-partner-selector [routeId]="routeId" class="component no-exwork" [showhelp]="true"
            [readonly]="readonly" (valueChanged)="getData()"></shared-route-partner-selector>

    </div>


    <div class="detall" *ngIf="optionExpanded == 'phaseSlot'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.PHASESLOT.BACK.CAPTION' | translate}}</button-link>
        <shared-active-slots-item-phase [phase]="phaseSelected" (refreshRoute)="goToNormal()" class="no-help"
            [hideLink]="true" [autoGetSlots]="true">
        </shared-active-slots-item-phase>
    </div>
    <div class="detall" *ngIf="optionExpanded == 'forcedStrongAddress'">
        <button-link variant="back" class="line left" (click)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.PHASEFORCEDSTRONGADDRESS.BACK.CAPTION' | translate}}</button-link>
        <shared-phase-change-strongaddress [phase]="phaseSelected" (valueChanged)="goToNormal()"
            class="component activeslots">
        </shared-phase-change-strongaddress>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'phaseSignatureCMR'">
        <button-link variant="back" class="line left" (click)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.PHASESIGNATURECMR.BACK.CAPTION' | translate}}</button-link>

        <shared-phase-cmr class="component activeslots" [phase]="phaseSelected">
        </shared-phase-cmr>

    </div>

    <div class="detall" *ngIf="optionExpanded == 'routesGrouping'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.ROUTESGROPING.BACK.CAPTION' | translate}}</button-link>
        <shared-route-group [routeId]="routeId" (finish)="onFinishRouteGroup($event)">
        </shared-route-group>
    </div>


    <div class="detall" *ngIf="optionExpanded == 'routesDivided'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.ROUTESDIVIDED.BACK.CAPTION' | translate}}</button-link>
        <shared-route-divided [routeId]="routeId" (finish)="onFinishRouteGroup($event)">
        </shared-route-divided>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'routesGroupShipment'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.ROUTESGROPSHIPMENT.BACK.CAPTION' | translate}}</button-link>
        <shared-route-group-child-list-component [routeId]="routeId"></shared-route-group-child-list-component>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'routeLog'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.ROUTELOG.BACK.CAPTION' | translate}}</button-link>
        <shared-route-item-log-maintenance [routeId]="routeId">
        </shared-route-item-log-maintenance>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'routeChat'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.ROUTECHAT.BACK.CAPTION' | translate}}</button-link>
        <shared-chat class="suau no-participants" [notes]="true"
            [forcedOwnerParticipant]="routeChatForcedOwnerParticipant">
        </shared-chat>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'routeTelemetry'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.ROUTETELEMETRY.BACK.CAPTION' | translate}}</button-link>
        <shared-telemetry class="fullheight reduced" [model]="'Route'" [modelId]="routeId"></shared-telemetry>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'routeMap'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.ROUTEMAP.BACK.CAPTION' | translate}}</button-link>
        <shared-map-route class="active-slots" [routeId]="routeId">
        </shared-map-route>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'phasesChangeDates'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.PHASESCHANGEDATES.BACK.CAPTION' | translate}}</button-link>
        <shared-phase-changedates-component [routeId]="routeId" class="component">
        </shared-phase-changedates-component>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'modifyBanner'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.MODIFYBANNER.BACK.CAPTION' | translate}}</button-link>
        <shared-banners [routeId]="routeId" class="component">
        </shared-banners>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'changeStatus'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.CHANGESTATUS.BACK.CAPTION' | translate}}</button-link>
        <shared-route-changestatus [routeId]="routeId" [fromActiveRoutes]="false" class="component">
        </shared-route-changestatus>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'changeConditions'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.CHANGECONDITIONS.BACK.CAPTION' | translate}}</button-link>
        <shared-route-changeconditions [routeId]="routeId" [fromActiveRoutes]="false" class="component">
        </shared-route-changeconditions>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'changeTags'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.CHANGETAGS.BACK.CAPTION' | translate}}</button-link>
        <shared-route-changetags [routeId]="routeId" class="component" [showtitle]="true" [readonly]="readonly">
        </shared-route-changetags>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'editAdditionalData'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.EDITADDITIONALDATA.BACK.CAPTION' | translate}}</button-link>
        <shared-route-item-edit-additionaldata [route]="selectedRoute" class="component" [showtitle]="true"
            [readonly]="readonly" (valueChange)="getData()">
        </shared-route-item-edit-additionaldata>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'times'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.CHANGESTATUS.BACK.CAPTION' | translate}}</button-link>
        <shared-statustime-component [routeId]="routeId" class="component">
        </shared-statustime-component>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'cmr'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.CMR.BACK.CAPTION' | translate}}</button-link>
        <shared-active-slots-item-cmr [routeId]="routeId" [readonly]="readonly">
        </shared-active-slots-item-cmr>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'booking'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.BOOKING.BACK.CAPTION' | translate}}</button-link>
        <shared-route-booking [routeId]="routeId" [readonly]="readonly">
        </shared-route-booking>
    </div>

    <div class="detall" *ngIf="optionExpanded == 'documentaryreview'">
        <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
            'SHARED.ACTIVE.SLOTS.ITEM.DOCUMENTARYREVIEW.BACK.CAPTION' | translate}}</button-link>
        <shared-documentaryreview-route-item [routeId]="routeId" [readonly]="readonly">
        </shared-documentaryreview-route-item>
    </div>

    <div class="dummy-block">
        <span class="dummy-text">.</span>
    </div>
    <!--
(clickParticipant)="clickParticipant($event)"
    <shared-route-item-edit-maintenance *ngIf="selectedOption == 'INFO'" [route]="route"
        (valueChange)="onValueChange($event)"></shared-route-item-edit-maintenance>
    

    <shared-route-division *ngIf="selectedOption == 'DIVISION'" [route]="route"
        (refreshRoute)="onActionDivision($event)">
    </shared-route-division>
    <shared-route-childofdivision *ngIf="selectedOption == 'ADDCHILDOFDIVISION'" [viewmode]="'add'" [route]="route"
        (refreshRoute)="onActionDivision($event)">
    </shared-route-childofdivision>
    <shared-route-childofdivision *ngIf="selectedOption == 'DELETECHILDOFDIVISION'" [viewmode]="'delete'"
        [route]="route" (refreshRoute)="onActionDivision($event)">
    </shared-route-childofdivision>
    <shared-route-childofdivision *ngIf="selectedOption == 'COMPACT'" [viewmode]="'compact'" [route]="route"
        (refreshRoute)="onActionDivision($event)">
    </shared-route-childofdivision>
    <shared-route-childofdivision *ngIf="selectedOption == 'DELIVERIES'" [viewmode]="'info'" [route]="route"
        [isActiveRoutes]="'true'">
    </shared-route-childofdivision>


    -->
</ng-container>