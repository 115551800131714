<ng-container *ngIf="totem">
    <div class="page-title">
        <div *ngIf="totem.arriveMustConfirmWithSecret || totem.arriveMustConfirmWithDriverPhone">
            {{'TOTEMPAGE.IDVERIFICATION.TITLE' | translateIn:totem.language | async}}
        </div>
        <div *ngIf="!totem.arriveMustConfirmWithSecret && !totem.arriveMustConfirmWithDriverPhone">
            {{'TOTEMPAGE.IDVERIFICATION.MISSINGCONFIGURATION' | translateIn:totem.language | async}}
        </div>
    </div>
    
    <div class="column-container" *ngIf="totem.arriveMustConfirmWithSecret || totem.arriveMustConfirmWithDriverPhone">
        <div class="column-central">
            <input class="searchbox" 
                placeholder="{{ getPlaceHolder() | translateIn:totem.language | async}}" type=text
                [(ngModel)]="idVerifyText" (ngModelChange)="this.idVerifyTextSubject.next($event)">

            <span [innerHtml]="totem.idVerificationHelp"></span>
        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta">
                <button class="button-action" (click)="confirmacioRutaAcceptar()" [disabled]="idVerifyText==''">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{'TOTEMPAGE.IDVERIFICATION.CONFIRMATION.BUTTON.ADD.CODE'|
                        translateIn:totem.language | async}}</div>
                </button>
            </div>
        </div>
    </div>
</ng-container>