<ng-container *ngIf="totem && totem.identificationSelected">
    <div class="page-title">
        {{getTitle() | translateIn:totem.language | async}} {{getTitle()}} {{totem.step}}
    </div>
    <div class="column-container">
        <div class="column-central">


            <!--Arriba tard -->
            <div *ngIf="totem.step==totemSteps.slotYourAreLate && !showIntervals" class="block-slot">
                <div class="slotInfo xslotInfo yourarelate">
                    <div class="primeralinia">
                        <span class="text">{{totem.identificationSelected.slotInfo.onDate
                            | date }}</span>
                    </div>
                    <div class="segonalinia">
                        <span class="text">
                            {{ totem.identificationSelected.slotInfo.interval | interval }}
                        </span>
                    </div>
                </div>

                <div class="page-help" [innerHtml]="getHelp() | translateIn:totem.language | async">
                </div>
            </div>

            <!--Sense slot -->
            <div *ngIf="totem.step==totemSteps.slotWithOutSlot && !showIntervals" class="block-slot">
                <div class="slotInfo xslotInfo withoutslot">
                    <div class="primeralinia">
                        <span class="text">{{'TOTEMPAGE.SLOT.NOSLOT.FIRST'| translateIn:totem.language | async}}</span>
                    </div>
                    <div class="segonalinia">
                        <span class="text">{{'TOTEMPAGE.SLOT.NOSLOT.SECOND'| translateIn:totem.language | async}}</span>
                    </div>
                </div>

                <div class="page-help" [innerHtml]="getHelp() | translateIn:totem.language | async">
                </div>
            </div>

            <!--Amb slot, ves a carregar -->
            <div *ngIf="totem.step==totemSteps.slotGoDock" class="block-slot">
                <div class="slotInfo xslotInfo">
                    <div class="primeralinia">
                        <span class="date"
                            *ngIf="totem.identificationSelected.slotInfo != null">{{totem.identificationSelected.slotInfo.onDate
                            | date }}</span>
                    </div>
                    <div class="segonalinia">
                        <span class="interval" *ngIf="totem.identificationSelected.slotInfo">
                            {{ totem.identificationSelected.slotInfo.interval | interval }}
                        </span>
                    </div>
                </div>
                <div class="text showBlockedEnterSystem"
                    *ngIf="phaseSlotInfo != null && phaseSlotInfo.isBlockedEnterSystem">
                    {{'TOTEMPAGE.SLOT.SHOWBLOCKEDENTERSYSTEM.CAPTION' |
                    translate}}
                </div>
                <div class="page-help" [innerHtml]="getHelp() | translateIn:totem.language | async">
                </div>
            </div>

            <!--Amb slot, ves al parking -->
            <div *ngIf="totem.step==totemSteps.slotGoParking && !showIntervals" class="block-slot">
                <div class="slotInfo xslotInfo">
                    <div class="primeralinia">
                        <span class="date"
                            *ngIf="totem.identificationSelected.slotInfo != null">{{totem.identificationSelected.slotInfo.onDate
                            | date }}</span>
                    </div>
                    <div class="segonalinia">
                        <span class="interval" *ngIf="totem.identificationSelected.slotInfo">
                            {{ totem.identificationSelected.slotInfo.interval | interval }}
                        </span>
                    </div>
                </div>
                <div class="text showBlockedEnterSystem"
                    *ngIf="phaseSlotInfo != null && phaseSlotInfo.isBlockedEnterSystem">
                    {{'TOTEMPAGE.SLOT.SHOWBLOCKEDENTERSYSTEM.CAPTION' |
                    translate}}
                </div>
                <div class="page-help" [innerHtml]="getHelp() | translateIn:totem.language | async">
                </div>
            </div>

            <div *ngIf="totem.step==totemSteps.estaDins" class="block-slot">
                <div class="slotInfo xslotInfo">
                    <div class="primeralinia">
                        <span class="date"
                            *ngIf="totem.identificationSelected.slotInfo != null">{{totem.identificationSelected.slotInfo.onDate
                            | date }}</span>
                    </div>
                    <div class="segonalinia">
                        <span class="interval" *ngIf="totem.identificationSelected.slotInfo">
                            {{ totem.identificationSelected.slotInfo.interval | interval }}
                        </span>
                    </div>
                </div>
                <div class="page-help" [innerHtml]="getHelp() | translateIn:totem.language | async">
                </div>
            </div>

            <div class="botonera">
                <shared-totem-slotpicker-component *ngIf="showIntervals" class="relative totem"
                    [phaseId]="totem.identificationSelected.phaseId" [totem]="totem"
                    (slotChanged)="onSlotChanged()"></shared-totem-slotpicker-component>
            </div>
        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta">
                <button class="button-action" (click)="buttonOk()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{ getButtonActionCode() | translateIn:totem.language | async}}
                    </div>
                    <div class="name">{{ getButtonActionName() | translateIn:totem.language | async}}
                    </div>
                </button>

                <button class="button-action alternate" (click)="showIntervals=!showIntervals"
                    *ngIf="totem.canChangeSlot">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{'TOTEMPAGE.SLOT.CHANGESLOT.LINK.SHOW.CODE'| translateIn:totem.language
                        | async}}
                    </div>
                    <div class="name">{{'TOTEMPAGE.SLOT.CHANGESLOT.LINK.SHOW.NAME'| translateIn:totem.language
                        | async}}
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-container>