<div class="container" [ngClass]="{'loading': loading, 'nooptions': nooptions}">
    <div *ngIf="phase != null" class="block selector-slots-container canviSlot"
        [ngClass]="{'isSlotBlocked':phase.isSlotBlocked,'incompatibleSlot':phase.incompatibleSlot,'oneSelected':(phase.intervalInfo!=null && phase.intervalInfo.addressTimetableIntervalId!=null && phase.intervalInfo.addressTimetableIntervalId!=0)}">
        <div>
            <div class="botonera-buttonInterval">

                <div class="line" *ngIf="!loading">
                    <button class="button-action ascolumn-dreta" *ngIf="!loading" (click)="getSlots()">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                        <div class="code">{{'SHARED.TOTEM.SLOTPICKER.REFRESHSLOTSBUTTON.CODE'|
                            translateIn:totem.language | async}}
                        </div>
                        <div class="name">{{'SHARED.TOTEM.SLOTPICKER.REFRESHSLOTSBUTTON.NAME'|
                            translateIn:totem.language | async}}
                        </div>
                    </button>
                </div>
                <div class="listintervals">
                    <ng-container *ngFor="let item of addressintervals | sortBy:[]; let i = index;">
                        <div class="date-break" *ngIf="trencaData(i, item)">
                            <div class="line"></div>
                            <div class="date-container">
                                <div class="date">{{item.onDate | date}}</div>
                            </div>
                        </div>
                        <div class="buttonInterval item"
                            [ngClass]="{'swapVision': item.canSwap||item.canObtain, 'assignable':item.assignable,'no-assignable':!item.assignable,'notrecommendable':item.notRecommendable,'inactive':item.inActive, 'selected-item': isSelected(item), 'impossible-interval': item.impossibleInterval, 'swap-interval': item.canSwap, 'obtain-interval': item.canObtain }"
                            (click)="onIntervalSelected(item)"
                            matTooltip="{{ 'DESKTOP.SLOT.PICKER.COMPONENT.SLOT.BUTTON.TOOLTIP' | translate: { slotAvailableByMe:item.slotAvailableByMe, slotOccupiedByMe:item.slotOccupiedByMe, sum: item.slotAvailableByMe+item.slotOccupiedByMe,slotAvailable:item.slotAvailable,  slotCount:item.slotCount } }}">
                            <div class="routekey">
                                {{ item.routeKeySwap }}
                            </div>
                            <div class="dia">
                                {{item.onDate | date}}
                            </div>
                            <div class="hora">
                                {{item.interval | hourinterval}}
                            </div>
                        </div>
                    </ng-container>
                    <div class="loading-label onlynooptions">
                        {{ 'SHARED.NOOPTIONS.CAPTION' | translateIn:totem.language | async}}
                    </div>
                </div>
            </div>
            <shared-totem-loading *ngIf="loading" [totem]="totem"></shared-totem-loading>
        </div>
    </div>
</div>