<ng-container *ngIf="totem">
    <div class="page-title">
        {{ 'TOTEMPAGE.EXIT.TITLE' | translateIn:totem.language | async}}
    </div>
    <div class="column-container">
        <div class="column-central">
            <div *ngIf="totem.step==totemSteps.exitBloked" class="block-exit">
                <div class="text showBlockedExitSystem">
                    {{'TOTEMPAGE.EXIT.EXITBLOKED.CAPTION'| translateIn:totem.language | async}}
                </div>
            </div>
            <div *ngIf="totem.step==totemSteps.exitGate" class="block-exit">
                {{ 'TOTEMPAGE.EXIT.EXITGATE.CAPTION' |
                    translateInParams: {
                    langCode: totem.language,
                    params: { gates: getGatesToExit() }
                    } | async
                    }}
            </div>
            <div *ngIf="totem.step==totemSteps.exitGoOut" class="block-exit">
                {{'TOTEMPAGE.EXIT.EXITGOOUT.CAPTION'| translateIn:totem.language | async}}
            </div>
    
        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta">
                <button *ngIf="totem.step!=totemSteps.exitBloked" class="button-action" (click)="buttonOk()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{ getButtonActionCode() | translateIn:totem.language | async}}
                    </div>
                    <div class="name">{{ getButtonActionName() | translateIn:totem.language | async}}
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-container>