import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { HString, HTranslateService, PhaseController } from '@shared/src/public-api';
import { TotemController } from '@shared/src/controllers/totem/totem.controller';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { TotemSteps } from '@shared/src/controllers/totem/TotemSteps';

@Component({
    selector: 'shared-totem-exit',
    templateUrl: './shared.totem.exit.component.html',
    styleUrls: ['./shared.totem.exit.component.scss']
})
export class SharedTotemExitComponent implements OnInit, OnDestroy {

    constructor(
        protected totemService: TotemController,
        protected translateService: HTranslateService) {

    }

    public totemSteps = TotemSteps;

    ngOnInit(): void {
    }
    ngOnDestroy() {
    }

    private _totem: TotemDto;
    @Input()
    public set totem(value: TotemDto) {
        if (this._totem === value)
            return;
        this._totem = value;
        this.loadData();
    }
    public get totem(): TotemDto {
        return this._totem;
    }

    loadData(){
        if(this.totem != null && this.totem.identificationSelected != null){
           
        }
    }

    getGatesToExit(): string{
        if(this.totem != null && this.totem.identificationSelected != null)
           return this.totem.identificationSelected.gatesToExit;
        
        return "-";
    }


    @Output() totemChange = new EventEmitter<TotemDto>();

    @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();

    public getButtonActionCode(): string {
        return "TOTEMPAGE.EXIT." + HString.toUpperCase(this.totem.step) + ".BUTTON.CODE";
    }

    public getButtonActionName(): string {
        return "TOTEMPAGE.EXIT." + HString.toUpperCase(this.totem.step) + ".BUTTON.NAME";
    }

    public buttonOk() {
        this.onFinish.next();
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


