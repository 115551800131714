<ng-container *ngIf="totem">
    <div class="page-title">
        {{'TOTEMPAGE.IDENTIFICATION.TITLE' | translateIn:totem.language | async}}
    </div>
    <div class="column-container">
        <div class="column-central">
            <input class="searchbox" placeholder="{{'SEARCH'| translateIn:totem.language | async}}" type=text
                [(ngModel)]="searchText" (ngModelChange)="this.searchTextSubject.next($event)">
            <div class="search-help">
                <span *ngIf="totem.coincidentRoutesCount==0" [innerHtml]="startSearch"></span>
                <span *ngIf="totem.coincidentRoutesCount>0" [innerHtml]="searchResult"></span>
            </div>
            <div class="page-help" *ngIf="!totem.idVerification" [innerHtml]="totem.identificationHelp">
            </div>
            <div class="llista-rutes" *ngIf="totem.idVerification && totem.identificationSelected==null">
                <div class="idverification-help"
                    [innerHtml]="'TOTEMPAGE.IDENTIFICATION.IDVERIFICATION.HELP' | translateIn:totem.language | async">
                </div>
                <div class="items" #container>
                    <shared-totem-route *ngFor="let item of totem.routeList" [totem]="totem" [value]="item"
                        (click)="selectItem(item)"></shared-totem-route>
                    <div class="dummy"></div>
                </div>

                <div class="botoScroll" [ngClass]="{'scrollVisible': getShowScroll()}">
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                </div>
            </div>
            <div class="confirmacio-ruta" *ngIf="totem.idVerification && totem.identificationSelected!=null">
                <div class="confirmacio-help">
                    {{'TOTEMPAGE.IDENTIFICATION.CONFIRMATION.HELP'| translateIn:totem.language | async}}
                </div>
                <shared-totem-route [totem]="totem" [value]="totem.identificationSelected"></shared-totem-route>
            </div>
        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta" *ngIf="totem.idVerification && totem.identificationSelected!=null">
                <button class="button-action" (click)="confirmacioRutaAcceptar()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{'TOTEMPAGE.IDENTIFICATION.CONFIRMATION.BUTTON.ADD.CODE'|
                        translateIn:totem.language | async}}</div>
                    <div class="name">{{'TOTEMPAGE.IDENTIFICATION.CONFIRMATION.BUTTON.ADD.NAME'|
                        translateIn:totem.language | async}}</div>
                </button>
                <button class="button-cancel" (click)="confirmacioRutaCancelar()">
                    <i aria-hidden="true" class="fa fa-arrow-circle-left"></i>
                    <div class="code">{{'TOTEMPAGE.IDENTIFICATION.CONFIRMATION.BUTTON.CANCEL.CODE'|
                        translateIn:totem.language | async}}</div>
                    <div class="name">{{'TOTEMPAGE.IDENTIFICATION.CONFIRMATION.BUTTON.CANCEL.NAME'|
                        translateIn:totem.language | async}}</div>
                </button>
            </div>
        </div>
    </div>
</ng-container>
<shared-totem-loading *ngIf="loading>0" [totem]="totem"></shared-totem-loading>