import { Location } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { PhaseCardItemDto } from '@shared/src/dtos/phase/PhaseCardItemDto';
import { RouteCardItemExpandDto } from '@shared/src/dtos/route/RouteCardItemExpandDto';
import { RouteGroupDto } from '@shared/src/dtos/route/RouteGroupDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { RoutePermissionTypesRules } from '@shared/src/enums/RoutePermissionTypes';
import { HomeController, HString, RouteController, SharedActiveSlots } from '@shared/src/public-api';

@Component({
  selector: 'shared-active-slots-item',
  templateUrl: './shared.active.slots.item.html',
  styleUrls: ['./shared.active.slots.item.scss']
})
export class SharedActiveSlotsItem implements OnInit {

  public allLoaded: boolean = false;
  public useIonic: boolean = false;
  constructor(public activatedRoute: ActivatedRoute,
    public router: Router, public location: Location, protected routeController: RouteController, protected openRouteController: OpenRouteController,
    @Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();

    //Fem subscribe per detectar només el location.back i location.forward que s'executen  només al anar endevant i enrera en els botons del navegador
    //Nomes ho fem amb aquests dos casos ja que o sinó esta constantment detectant canvis al canviar els parametres de url en el routeTo
    this.location.subscribe(
      ((value: PopStateEvent) => {
        console.log(value);
        this.loadParametersLocation();
      }),
      (ex => {
      })
    );
  }
  public loadParametersLocation() {

    let path = this.location.path();
    if (path == "")
      return;

    let tree = this.router.parseUrl(path);
    let queryParams = tree.queryParams;

    let option = queryParams[SharedActiveSlots.PARAMETEROPTIONNAME];
    if (option)
      this.optionExpanded = option;

    let phaseId = queryParams[SharedActiveSlots.PARAMETERPHASEIDNAME];
    if (phaseId && this._phasesSlots != null) {
      let i = 0;
      for (i = 0; i < this._phasesSlots.length; i++)
        if (this._phasesSlots[i].phaseId == phaseId)
          this.onPhaseSlot(this._phasesSlots[i]);
    }


    this.allLoaded = true;
  }
  routeTo() {
    if (!this.allLoaded || this.useIonic)
      return;

    if (!HString.startsWith(this.router.url, "/operation/activeslots"))
      return;

    let object = { ...this.activatedRoute.snapshot.queryParams };
    if (!HString.isNullOrNullString(this.optionExpanded))
      object[SharedActiveSlots.PARAMETEROPTIONNAME] = this.optionExpanded;
    else
      delete object[SharedActiveSlots.PARAMETEROPTIONNAME];

    if (this.phaseSelected != null)
      object[SharedActiveSlots.PARAMETERPHASEIDNAME] = this.phaseSelected.phaseId;
    else
      delete object[SharedActiveSlots.PARAMETERPHASEIDNAME];

    //Aqui no estem rederigint, només estem actualtzant els queryParams perque es vegi reflectida la URL. 
    //Al no redirigir (que ja es el que volem perquè no es necessàri), no està refrescant la pagina i no entrarà pel ngOnInit()
    this.router.navigate([".", {}], { relativeTo: this.activatedRoute, queryParams: object, queryParamsHandling: '' });
  }

  @Input() public readonly: boolean;

  @Output() clickBack = new EventEmitter<boolean>();

  public _routeId: number;
  @Input()
  public get routeId(): number {
    return this._routeId;
  }
  public set routeId(value: number) {
    if (this._routeId === value)
      return;
    this._routeId = value;
    this.optionExpanded = "";
    this.routeTo();
    this.getData();
  }

  public _selectedRoute: RouteCardItemExpandDto;
  public get selectedRoute(): RouteCardItemExpandDto {
    return this._selectedRoute;
  }
  public set selectedRoute(value: RouteCardItemExpandDto) {
    if (this._selectedRoute === value)
      return;
    this._selectedRoute = value;
    this.routeId = value != null ? value.routeId : 0;
    this.loadPhasesSlots();
  }

  public agrupationURI: string = "";
  public algoAFer: boolean = false;
  public _phasesSlots: PhaseCardItemDto[] = new Array();
  public loadPhasesSlots() {
    let phases: PhaseCardItemDto[] = new Array();

    //Només em de mostrar les que requereixen slot
    if (this.selectedRoute && this.selectedRoute.phases && this.selectedRoute.phases.length > 0) {
      this.selectedRoute.phases.forEach(element => {
        if (element.requiresSlot) {
          phases.push(element);
        }
      });
    }
    this._phasesSlots = phases;
    this.algoAFer = this._phasesSlots && this._phasesSlots.length > 0;
    this.loadParametersLocation();
  }

  onClickBack(event) {
    this.clickBack.next(true);
  }

  ngOnInit(): void {
    this.loadParametersLocation();
  }

  public optionExpanded: string = "";

  onClickLinkOption(option: string) {
    if (option == this.optionExpanded) {
      //Estem tancat l'opció oberta
      this.optionExpanded = "";
      this.routeTo();
      return;
    }
    if (option == 'routesGrouping') {
      if (!this.selectedRoute.canGrouping)
        return;
    }

    this.optionExpanded = option;
    this.routeTo();
  }

  sendingToDriver: boolean;
  sendToDriver() {
    if (this.sendingToDriver)
      return;
    this.sendingToDriver = true;
    this.openRouteController.notifyDriver(this.routeId).subscribe(data => {
      this.sendingToDriver = false;
    });
  }

  public phaseSelected: PhaseCardItemDto;
  onPhaseSlot(value: any) {
    this.phaseSelected = value;
    this.goTo('phaseSlot');
  }
  onPhaseForcedStrongAddress(value: any) {
    this.phaseSelected = value;
    this.goTo('forcedStrongAddress');
  }
  onPhaseSignatureCMR(value: any) {
    this.phaseSelected = value;
    this.goTo('phaseSignatureCMR');
  }
  goTo(option: string) {
    this.optionExpanded = option;
    this.routeTo();
  }
  onFinishRouteGroup(value: RouteGroupDto) {
    if (value == null || value.routeGroup == null) {
      this.clickBack.next(true);
      return;
    }
    this._routeId = value.routeGroup.routeId;
    this.loadingToNormal = false;
    this.goToNormal();
  }

  public loadingToNormal: boolean;
  goToNormal() {
    if (this.loadingToNormal)
      return;
    this.loadingToNormal = true;
    this.routeController.getRouteCardItemExpand(this.routeId).subscribe(data => {
      this.loadingToNormal = false;
      this.selectedRoute = data;
      this.phaseSelected = null;
      this.optionExpanded = '';
      this.routeTo();
      this.wellCome();
    });
  }

  public canAssignDriverAndPlates: boolean = true;
  public showAssignDriverAndPlates: boolean = true;

  public getData() {
    if (HLong.isNullOrNullLong(this.routeId)) {
      this.selectedRoute = null;
      return;
    }
    this.routeController.getRouteCardItemExpand(this.routeId).subscribe(data => {
      this.selectedRoute = data;
      this.wellCome();
    });
  }
  public routeChatForcedOwnerParticipant: ParticipantDto;
  public wellCome() {
    if (this.selectedRoute) {
      this.routeChatForcedOwnerParticipant = ParticipantDto.buildByParams(this.selectedRoute.routeId, ChatParticipantTypes.Route);
      this.canAssignDriverAndPlates = RoutePermissionTypesRules.canAssignDriverAndPlatesOnRoute(this.selectedRoute);
      this.showAssignDriverAndPlates = RoutePermissionTypesRules.showAssignDriverAndPlatesOnRoute(this.selectedRoute);
      this.agrupationURI = this.homeController.createRouteIdLinkToActiveSlots(this.selectedRoute.routeParentGroupId);
    } else {
      this.agrupationURI = "";
      this.routeChatForcedOwnerParticipant = null;
      this.canAssignDriverAndPlates = false;
      this.showAssignDriverAndPlates = false;
    }
  }

  onGoParentGrouped() {
    if (this.selectedRoute && this.selectedRoute.routeParentGroupId > 0) {

      let object = {};

      if (!HLong.isNullOrNullLong(this.selectedRoute.routeParentGroupId)) {
        object[SharedActiveSlots.PARAMETERROUTEIDNAME] = this.selectedRoute.routeParentGroupId;
        //Aqui no estem rederigint, només estem actualtzant els queryParams perque es vegi reflectida la URL. 
        //Al no redirigir (que ja es el que volem perquè no es necessàri), no està refrescant la pagina i no entrarà pel ngOnInit()
        this.router.navigate([".", {}], { relativeTo: this.activatedRoute, queryParams: object, queryParamsHandling: '' });
      }
    }
  }
}
