import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { SharedInactivityService } from '@shared/src/services/inactivity/SharedInactivityService';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { TranslateService } from '@ngx-translate/core';
import { HString } from '@shared/src/public-api';
import { RoutePartCMRSignatureAgents } from '@shared/src/controllers/route/cmr/RoutePartCMRSignatureAgents';
import { UUIDSignatureDto } from '@shared/src/controllers/route/cmr/UUIDSignatureDto';
import { OpenRouteCMRController } from '@shared/src/controllers/open/openroutecmr.controller';

@Component({
    selector: 'shared-sign',
    templateUrl: './shared.sign.component.html',
    styleUrls: ['./shared.sign.component.scss']
})
export class SharedSignComponent implements OnInit {

    constructor(protected systemController: SystemController,
        protected activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        protected inactivityService: SharedInactivityService,
        protected openRouteCMRController: OpenRouteCMRController) {
    }

    public traduccio: string = "";
    public value: UUIDSignatureDto;

    @Output() signedChanged = new EventEmitter<boolean>();

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.callResolveParams();
        });
        this.activatedRoute.params.subscribe(routeParams => {
            this.callResolveParams();
        });

        this.callResolveParams();
    }

    private _resolvingParams: boolean = false;
    private callResolveParams() {
        if (this._resolvingParams)
            return;
        this._resolvingParams = true;
        this.resolveParams();
        this._resolvingParams = false;
    }

    public __uuid: string;
    @Input()
    set uuid(value: string) {
        if (this.__uuid === value)
            return;
        this.__uuid = value;
        this.loadData();
    }
    get uuid(): string {
        return this.__uuid;
    }

    public __agent: RoutePartCMRSignatureAgents = null;
    @Input()
    set agent(value: RoutePartCMRSignatureAgents) {
        if (this.__agent === value)
            return;
        this.__agent = value;
        this.loadData();
    }
    get agent(): RoutePartCMRSignatureAgents {
        return this.__agent;
    }


    private resolveParams(): boolean {
        let result: boolean = false;
        const routeParams = this.activatedRoute.snapshot.paramMap;
        const queryParams = this.activatedRoute.snapshot.queryParamMap;
        const routeUrl = this.activatedRoute.snapshot.url;

        if (routeParams.get('uuid'))
            this.__uuid = routeParams.get('uuid');
        if (routeParams.get('agent')) {
            let agentParam = routeParams.get('agent') as keyof typeof RoutePartCMRSignatureAgents;
            this.__agent = RoutePartCMRSignatureAgents[agentParam] || RoutePartCMRSignatureAgents.none;
        }
        this.loadData();

        return result;
    }
    loadData() {
        if (!HString.isNullOrNullString(this.uuid) && this.agent != null) {
            this.openRouteCMRController.getSignByAgentAndUUID(this.agent, this.uuid).subscribe(d => {
                this.value = d;
            });
        }
        else
            this.value = null;
    }

    onSignedChanged(){
        this.signedChanged.next();
    }


    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


