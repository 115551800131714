import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../base.controller';
import { SecurityController } from '../security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { UUIDSignatureDto } from '../route/cmr/UUIDSignatureDto';
import { Route_eCMRDto } from '@shared/src/dtos/route/cmr/Route_eCMRDto';
import { RoutePartCMRSignatureAgents } from '../route/cmr/RoutePartCMRSignatureAgents';
import { RoutePartCMRSignDto } from '../route/cmr/RoutePartCMRSignDto';
import { RoutePartCMRSignatureDto } from '../route/cmr/RoutePartCMRSignatureDto';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { DocumentDetailDto } from '@shared/src/dtos/document/DocumentDetailDto';


@Injectable({
    providedIn: 'root',
})
export class OpenRouteCMRController extends BaseController {

    private base = '/public/routecmr'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }


    public getSignByAgentAndUUID(agent: any, uuid: string): Observable<UUIDSignatureDto> {
        return this.get(this.base + "/sign/agent/" + agent + "/uuid/" + uuid);
    }
    public getRoute_eCMR(routePartCMRId: number): Observable<Route_eCMRDto> {
        return this.get(this.base + "/routeecmr/" + routePartCMRId);
    }

    public getLastDocumentDetailsCMR(routePartCMRId: number): Observable<DocumentDetailDto[]> {
        return this.get(this.base + "/routeecmr/last/documentdetails/cmr/" + routePartCMRId);
    }

    getRoutePartCMRSignature(routePartCMRId: number, type: RoutePartCMRSignatureAgents): Observable<RoutePartCMRSignDto> {
        return this.get(this.base + "/routepartcmr/" + routePartCMRId + "/sign/" + type);
    }
    getSignUUIDSignature(singUUID: string, type: RoutePartCMRSignatureAgents): Observable<RoutePartCMRSignDto> {
        return this.get(this.base + "/signUUID/" + singUUID + "/sign/" + type);
    }

    getRoutePartCMRSignatureNew(routePartCMRId: number, type: RoutePartCMRSignatureAgents): Observable<RoutePartCMRSignDto> {
        return this.get(this.base + "/routepartcmr/" + routePartCMRId + "/sign/" + type + "/new");
    }
    getRoutePartCMRSignatureNewUUID(uuid: string, type: RoutePartCMRSignatureAgents): Observable<RoutePartCMRSignDto> {
        return this.get(this.base + "/uuid/" + uuid + "/sign/" + type + "/new");
    }

    doSignature(routePartCMRId: number, type: RoutePartCMRSignatureAgents, signatureObject: RoutePartCMRSignatureDto): Observable<Boolean> {
        return this.postBusy(this.base + "/routepartcmr/" + routePartCMRId + "/sign/" + type, signatureObject, "doSignature");
    }
    doSignatureUUID(uuid: string, type: RoutePartCMRSignatureAgents, signatureObject: RoutePartCMRSignatureDto): Observable<Boolean> {
        return this.postBusy(this.base + "/uuid/" + uuid + "/sign/" + type, signatureObject, "doSignatureUUID");
    }

    doPrint(routePartCMRId: number, type: RoutePartCMRSignatureAgents): Observable<Boolean> {
        return this.getBusy(this.base + "/routepartcmr/" + routePartCMRId + "/print/" + type);
    }
    doPrintUUID(uuid: string, type: RoutePartCMRSignatureAgents): Observable<Boolean> {
        return this.getBusy(this.base + "/uuid/" + uuid + "/print/" + type);
    }

    doRemoveSignature(uuid: string, type: RoutePartCMRSignatureAgents): Observable<Boolean> {
        return this.getBusy(this.base + "/uuid/" + uuid + "/removesignature/" + type);
    }

    getRoutePartCMRByUUID(uuidQR: string): Observable<number> {
        return this.get(this.base + "/qr/" + uuidQR);
    }

    getRoutePartCMRsByPhaseUUID(phaseUUID: string): Observable<RoutePartCMRDto[]> {
        return this.get(this.base + "/byphaseuuid/" + phaseUUID);
    }
}
