import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OpenRouteCMRController } from '@shared/src/controllers/open/openroutecmr.controller';
import { RoutePartCMRSignatureAgents } from '@shared/src/controllers/route/cmr/RoutePartCMRSignatureAgents';
import { RoutePartCMRSignatureDto } from '@shared/src/controllers/route/cmr/RoutePartCMRSignatureDto';
import { RoutePartCMRSignDto } from '@shared/src/controllers/route/cmr/RoutePartCMRSignDto';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { HString } from '@shared/src/public-api';

@Component({
    selector: 'shared-sign-cmr-maintenance',
    templateUrl: './shared.sign.cmr.maintenance.component.html',
    styleUrls: ['./shared.sign.cmr.maintenance.component.scss']
})
export class SharedSignCMRMaintenanceComponent implements OnInit {

    public showHistory: boolean = false;

    constructor(public router: Router, public openRouteCMRController: OpenRouteCMRController,
        @Inject('SecurityController') public securityController: SecurityController,
        private translateService: TranslateService) {
        this.securityController.getStoredUserDto().subscribe(data => {
            if (data != null)
                this._userReadOnly = data.isReadOnly;
            this.computeReadOnly();
        })
    }

    @Output() signedChanged = new EventEmitter<boolean>();

    public _userReadOnly: boolean = false;
    public _readonly: boolean = false;
    @Input()
    set readonly(value: boolean) {
        if (this._readonly === value)
            return;

        this._readonly = value;
        this.computeReadOnly();
    }
    get readonly(): boolean {
        return this._readonly;
    }

    public applyReadOnly: boolean = false;
    public computeReadOnly() {
        this.applyReadOnly = this._readonly || this._userReadOnly;
    }

    public _routePartCMR: RoutePartCMRDto;
    @Input()
    set routePartCMR(value: RoutePartCMRDto) {
        if (this._routePartCMR === value)
            return;

        this._routePartCMR = value;
        this.loadData();
    }
    get routePartCMR(): RoutePartCMRDto {
        return this._routePartCMR;
    }

    private _routePartCMRId: number = null;
    @Input()
    public set routePartCMRId(value: number) {
        if (this._routePartCMRId === value)
            return;
        this._routePartCMRId = value;
        this.loadData();
    }
    public get routePartCMRId(): number {
        return this._routePartCMRId;
    }

    private _signUUID: string = null;
    @Input()
    public set signUUID(value: string) {
        if (this._signUUID === value)
            return;
        this._signUUID = value;
        this.loadData();
    }
    public get signUUID(): string {
        return this._signUUID;
    }

    private _type: RoutePartCMRSignatureAgents;
    @Input()
    public set type(type: RoutePartCMRSignatureAgents) {
        if (this._type === type)
            return;
        this._type = type;
        this.loadData();
    }
    public get type(): RoutePartCMRSignatureAgents {
        return this._type
    }

    public options: OptionsStripDto;
    private _value: RoutePartCMRSignDto;
    public set value(value: RoutePartCMRSignDto) {
        if (this._value === value)
            return;
        this._value = value;
        if(value != null)
            this.printDocumentation = value.defaultValuePrintCheck;
    }
    public get value(): RoutePartCMRSignDto {
        return this._value
    }
    public printDocumentation: boolean;

    public loadData() {
        if (this.routePartCMR != null && this.type != null) {
            this.openRouteCMRController.getRoutePartCMRSignature(this.routePartCMR.routePartCMRId, this.type).subscribe(d => {
                this.value = d;
                this.fileSignatureBytes = HString.toBase64ToArrayBuffer(d.fileContent);
            });
        } else if (!HLong.isNullOrNullLong(this.routePartCMRId) && this.type != null) {
            this.openRouteCMRController.getRoutePartCMRSignature(this.routePartCMRId, this.type).subscribe(d => {
                this.value = d;
                this.fileSignatureBytes = HString.toBase64ToArrayBuffer(d.fileContent);
            });
        } else if (!HString.isNullOrNullString(this.signUUID) && this.type != null) {
            this.openRouteCMRController.getSignUUIDSignature(this.signUUID, this.type).subscribe(d => {
                this.value = d;
                this.fileSignatureBytes = HString.toBase64ToArrayBuffer(d.fileContent);
            });
        } else {
            this.value = null;
        }
    }

    public selectedOptionId = "";
    onClickOption(option: OptionDto) {
        if (option != null)
            this.selectedOptionId = option.optionId;
        else
            this.selectedOptionId = "";
    }
    ngOnInit(): void {
    }

    newSign() {
        if (this.routePartCMR != null && this.type != null) {
            this.openRouteCMRController.getRoutePartCMRSignatureNew(this.routePartCMR.routePartCMRId, this.type).subscribe(d => {
                this.value = d;
                this.fileSignatureBytes = null;
            });
        } else if (!HString.isNullOrNullString(this.signUUID) && this.type != null) {
            this.openRouteCMRController.getRoutePartCMRSignatureNewUUID(this.signUUID, this.type).subscribe(d => {
                this.value = d;
                this.fileSignatureBytes = null;
            });
        } else {
            this.value = null;
        }
    }

    public fileSignatureBytes: any;
    public fileSignatureType: FileTypes.png;
    public onEndDrawSignature(signature: any) {
        this.fileSignatureBytes = signature.signbytes;
        this.fileSignatureType = signature.bytestype;
    }
    public onClearSignature($event: any) {
    }
    public cancelSignature() {
        this.fileSignatureBytes = null;
    }
    public doSignature() {
        let signatureObject = new RoutePartCMRSignatureDto(this.fileSignatureBytes, this.fileSignatureType, this.printDocumentation);

        if (this.routePartCMR != null) {
            this.openRouteCMRController.doSignature(this.routePartCMR.routePartCMRId, this.type, signatureObject).subscribe(d => {
                this.loadData();
                this.signedChanged.next();
            });
        } else if (!HString.isNullOrNullString(this.signUUID)) {
            this.openRouteCMRController.doSignatureUUID(this.signUUID, this.type, signatureObject).subscribe(d => {
                this.loadData();
                this.signedChanged.next();
            });
        }
    }

    public doPrint() {

        if (this.routePartCMR != null) {
            this.openRouteCMRController.doPrint(this.routePartCMR.routePartCMRId, this.type).subscribe(d => {
            });
        } else if (!HString.isNullOrNullString(this.signUUID)) {
            this.openRouteCMRController.doPrintUUID(this.signUUID, this.type).subscribe(d => {
            });
        }
    }

    public traduirTipus(tipus: string): string {
        return this.translateService.instant(HString.toUpperCase('ROUTEPARTCMRSIGNATUREAGENTS.ENUM.' + tipus));
    }
    public modeSignatura: number = 0;
    public classModeSignatura = "tot";
    clickModeSignatura() {
        this.modeSignatura++;
        if (this.modeSignatura % 2 === 0) {
            this.classModeSignatura = "tot";
        } else if (this.modeSignatura % 2 === 1) {
            this.classModeSignatura = "humana";
        }
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }

}
