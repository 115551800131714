import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccessControlController } from '@shared/src/controllers/accesscontrol/accesscontrol.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { PartnersController } from '@shared/src/controllers/link/partners.controller';
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { HColor } from "@shared/src/datatypes/HColor";
import { HDate } from "@shared/src/datatypes/HDate";
import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { HLong } from '@shared/src/datatypes/HLong';
import { PlateRouteDto } from '@shared/src/dtos/accesscontrol/PlateRouteDto';
import { SetPlateGateDto } from '@shared/src/dtos/accesscontrol/SetPlateGateDto';
import { AccessControlGatePlateInfoDto } from '@shared/src/dtos/accesscontrol/gate/plate/AccessControlGatePlateInfoDto';
import { GateItemDto } from '@shared/src/dtos/accesscontrol/gate/plate/GateItemDto';
import { AccessControlLogDto } from '@shared/src/dtos/accesscontrol/log/AccessControlLogDto';
import { DockDto } from '@shared/src/dtos/address/dock/DockDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { PartnerInfoDto } from '@shared/src/dtos/link/PartnerInfoDto';
import { PartnerListDto } from '@shared/src/dtos/link/list/PartnerListDto';
import { iPartnerDto } from '@shared/src/dtos/link/list/iPartnerDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { ChatController, PhaseCardDto, PhaseController, RouteCardDto, RouteController } from '@shared/src/public-api';
import { Subscription, timer as observableTimer } from 'rxjs';

@Component({
  selector: 'shared-accesscontrol-plateroute-info-component',
  templateUrl: './shared.accesscontrol.plateroute.info.component.html',
  styleUrls: ['./shared.accesscontrol.plateroute.info.component.scss'],
})
export class SharedAccessControlPlateRouteInfoComponent implements OnDestroy {
  constructor(private accessControlController: AccessControlController,
    protected chatController: ChatController,
    protected routeController: RouteController,
    protected phaseController: PhaseController,
    protected translateService: TranslateService,
    protected partnerController: PartnersController,
    @Inject('SecurityController') public securityController: SecurityController, @Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  public useIonic = false;


  public __plateRoute: PlateRouteDto;
  @Input()
  set plateRoute(value: PlateRouteDto) {
    if (this.__plateRoute === value)
      return;
    this.__plateRoute = value;
    this.loadPlateRouteData();
  }
  get plateRoute(): PlateRouteDto {
    return this.__plateRoute;
  }

  public __data: AccessControlGatePlateInfoDto;
  set data(value: AccessControlGatePlateInfoDto) {
    if (this.__data === value)
      return;
    this.__data = value;
    this.setOtherInfo();
    this.setGateOut();
  }
  get data(): AccessControlGatePlateInfoDto {
    return this.__data;
  }

  public GateOut: GateItemDto;
  setGateOut() {
    if (this.data == null || HLong.isNullOrNullLong(this.data.gateId)) {
      this.GateOut = null;
    } else if (this.data.allGates != null && this.data.allGates.length > 0) {
      this.data.allGates.forEach(element => {
        if (element.gateId == this.data.gateId && element.isInsideGate) {
          this.GateOut = element;
          return;
        }
      });
    }
  }

  public plateACaption: string;
  public setOtherInfo() {
    if (this.data != null)
      this.plateACaption = this.data.showPlateSubtitleAtPlateA ? this.data.plateSubtitle : this.data.plateA;
    else
      this.plateACaption = "";
  }

  public actionsGates: ActionsStripDto;
  public loadPlateRouteData() {
    this.accessControlController.getPlateRoute(this.plateRoute).subscribe(data => {
      this.data = data;
      this.actionsGates = null;
      if (this.data != null) {
        this.actionsGates = ActionsStripDto.buildFromActionsStripDto(this.data.actionsGates);
        if (this.data.phaseId != null)
          this.phaseController.getPhase(this.data.phaseId).subscribe(e => {
            this.phase = e;
          });
      }
      if (this.options == null)
        this.options = this.buildOptions(data);
      this.activaTimer();

      if (this.data.routeId != null)
        this.routeController.getRoute(this.data.routeId).subscribe(d => {
          this.route = d;

          this.carrier = null;
          if (this.route != null && this.route.routeId != null) {
            this.partnerController.getPartnerFromRoute(this.route.routeId).subscribe(d => {
              this.carrier = PartnerInfoDto.buildFromPartner(d);
            })
          }
        });
    });
  }
  public carrier: PartnerInfoDto = null;
  public options: OptionsStripDto;
  public actualOptionId: string = "GENERAL";
  public buildOptions(data: AccessControlGatePlateInfoDto): OptionsStripDto {
    let result = new OptionsStripDto();
    result.add(OptionDto.build("GENERAL", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.GENERAL.CAPTION", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.GENERAL.HELP", "fa-info-circle", 1, 1, 1, true, false));
    result.add(OptionDto.build("DRIVER", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.DRIVER.CAPTION", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.DRIVER.HELP", "fa-user-o", 2, 1, 1, false, false));
    result.add(OptionDto.build("SLOTS", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.SLOTS.CAPTION", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.SLOTS.HELP", "fa-calendar-check-o", 10, 1, 1, false, false));
    result.add(OptionDto.build("INTERNATIONAL", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.INTERNATIONAL.CAPTION", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.INTERNATIONAL.HELP", "", 13, -1, 1, false, false));
    result.add(OptionDto.build("ROUTEHISTORY", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.ROUTEHISTORY.CAPTION", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.ROUTEHISTORY.HELP", "", 15, -1, 1, false, false));
    result.add(OptionDto.build("CHAT", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.CHAT.CAPTION", "SHARED.ACCESSCONTROL.PLATEROUTE.INFO.COMPONENT.OPTIONS.CHAT.HELP", "", 20, -1, 1, false, false));
    if (this.data != null && this.data.isGrouped)
      result.add(OptionDto.build("GROUPED", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GROUPED.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GROUPED.HELP", "", 21, -1, 1, false, false));
    result.add(OptionDto.build("OBSERVATIONS", "SHARED.ROUTE.OBSERVATIONS.COMPONENT.TITLE", "SHARED.ROUTE.OBSERVATIONS.COMPONENT.HELPSHORT", "", 30, -1, 1, false, false));

    this.actualOptionId = result.getActualSelected().optionId;
    return result;
  }
  public forcedOwnerParticipant: ParticipantDto;
  public route: RouteCardDto;
  public phase: PhaseCardDto;
  onClickOption(option: OptionDto) {
    this.actualOptionId = option.optionId;
    if (option && option.optionId === "CHAT") {
      this.forcedOwnerParticipant = ParticipantDto.buildByParams(this.data.routeId, ChatParticipantTypes.Route);
    } else if (option && option.optionId === "SLOTS") {
      this.phaseController.getPhase(this.data.phaseId).subscribe(e => {
        this.phase = e;
      });
    }
  }
  clickParticipant(participant: ParticipantDto) {
    this.chatController.onClickParticipant(participant);
  }


  getDayOfWeek(value: HDateHour) {
    if (value == null)
      return "";

    return HDate.getDayOfWeek(HDateHour.getDate(value));
  }

  getGateName(gate: GateItemDto) {
    if (gate == null)
      return this.translateService.instant("ACCESSCONTROL.GATE.OUT");

    return gate.gateName;

  }

  getGateResource(gate: GateItemDto) {
    if (gate == null)
      return this.translateService.instant("ACCESSCONTROL.GATE.OUT");

    if (gate.isInsideGate)
      return gate.insideGateResource;
    return gate.outsideGateResource;

  }

  public getStyleGateTag(gate: GateItemDto) {
    if (gate != null && gate.gateKey != "Out") {
      let bcolor = HColor.getColorFromStringTable(this.translateService.instant(gate.gateName), this.homeController);
      return {
        "color": HColor.getWhiteBlackFromColor(bcolor),
        "background-color": bcolor
      };
    }

    return {};
  }


  getGateHeadingResource(gate: GateItemDto): string {
    if (gate == null)
      return "";

    if (gate.isInsideGate)
      return "GATE.OUT.HEADING";

    return "GATE.IN.HEADING";
  }

  onGateClick(gate: GateItemDto) {
    if (this.data == null || gate == null)
      return;

    //Si està dins, hem de sortir
    if (gate.isInsideGate) {
      this.accessControlController.exitPlateGate(SetPlateGateDto.build(this.data.accessControlId, this.data.plateA, this.data.routeId, this.data.phaseId, 0, gate.gateId)).subscribe(data => {
        if (data)
          this.data.gateId = 0;
        this.loadPlateRouteData();
        this.securityController.asideReloadData$.next(true);
      });
    } else {
      this.accessControlController.entryPlateGate(SetPlateGateDto.build(this.data.accessControlId, this.data.plateA, this.data.routeId, this.data.phaseId, gate.gateId, 0)).subscribe(data => {
        if (data)
          this.data.gateId = gate.gateId;
        this.loadPlateRouteData();
        this.securityController.asideReloadData$.next(true);
      });
    }
  }

  onClickAction($event: ActionDto) {
    if ($event == null)
      return;
    switch ($event.actionId) {
      case 'GETOUTOFALLGATESANDINIT':
        this.onGetOutOfAllGatesAndInitPhaseClick();
        break;
      case 'GETOUTOFALLGATES':
        this.onGetOutOfAllGatesClick();
        break;
      default:
        this.accessControlController.executeAction(SetPlateGateDto.build(this.data.accessControlId, this.data.plateA, this.data.routeId, this.data.phaseId, 0, 0),
          $event).subscribe(data => {
            this.loadPlateRouteData();
            this.securityController.asideReloadData$.next(true);
          });

    }
  }
  onGetOutOfAllGatesAndInitPhaseClick() {
    this.accessControlController.exitPlateOfAllGatesAndInitPhase(SetPlateGateDto.build(this.data.accessControlId, this.data.plateA, this.data.routeId, this.data.phaseId, 0, 0)).subscribe(data => {
      this.loadPlateRouteData();
      this.securityController.asideReloadData$.next(true);
    });
  }
  onGetOutOfAllGatesClick() {
    this.accessControlController.exitPlateOfAllGates(SetPlateGateDto.build(this.data.accessControlId, this.data.plateA, this.data.routeId, this.data.phaseId, 0, 0)).subscribe(data => {
      this.loadPlateRouteData();
      this.securityController.asideReloadData$.next(true);
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.desactivaTimer();
  }

  private static AUTOREFRESHFREQUENCYMILLISECONDS: number = 60000;
  private timerSubscription: Subscription;
  private activaTimer() {
    this.desactivaTimer();
    let timer = observableTimer(SharedAccessControlPlateRouteInfoComponent.AUTOREFRESHFREQUENCYMILLISECONDS, SharedAccessControlPlateRouteInfoComponent.AUTOREFRESHFREQUENCYMILLISECONDS);
    this.timerSubscription = timer.subscribe(t => {
      this.desactivaTimer();
      this.loadPlateRouteData();
    });
  }
  private desactivaTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  getResourceAccessControlType(element: AccessControlLogDto): string {
    if (element == null)
      return "ACCESSCONTROLTYPES.NONE";

    return "ACCESSCONTROLTYPES." + element.accessControlType.toUpperCase();
  }

  pretty(value) {
    return JSON.stringify(value);
  }
}
