import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { RoutePartCMRSignatureAgents } from '@shared/src/controllers/route/cmr/RoutePartCMRSignatureAgents';
import { RoutePartCMRSignListDto } from '@shared/src/controllers/route/cmr/RoutePartCMRSignListDto';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { HomeController, HString } from '@shared/src/public-api';

@Component({
    selector: 'shared-sign-cmr-log',
    templateUrl: './shared.sign.cmr.log.component.html',
    styleUrls: ['./shared.sign.cmr.log.component.scss']
})
export class SharedSignCMRLogComponent implements OnInit {

    @Input() public readonly: boolean = false;
    public showHistory: boolean = false;
    public useIonic: boolean = false;
    constructor(public router: Router, public routeCMRController: RouteCMRController,
        @Inject('SecurityController') public securityController: SecurityController,
        @Inject('HomeController') protected homeController: HomeController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            if(data != null)
                this.readonly = data.isReadOnly;
        })
        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();

    }


    public _routePartCMR: RoutePartCMRDto;
    @Input()
    set routePartCMR(value: RoutePartCMRDto) {
        if (this._routePartCMR === value)
            return;

        this._routePartCMR = value;
        this.loadData();
    }
    get routePartCMR(): RoutePartCMRDto {
        return this._routePartCMR;
    }

    private _type: RoutePartCMRSignatureAgents;
    @Input()
    public set type(type: RoutePartCMRSignatureAgents) {
        if (this._type === type)
            return;
        this._type = type;
        this.loadData();
    }
    public get type(): RoutePartCMRSignatureAgents {
        return this._type
    }

    private _signUUID: string = null;
    @Input()
    public set signUUID(value: string) {
        if (this._signUUID === value)
            return;
        this._signUUID = value;
        this.loadData();
    }
    public get signUUID(): string {
        return this._signUUID;
    }
    
    public value: RoutePartCMRSignListDto[];
    public loadData() {
        if (this.routePartCMR != null && this.type != null) {
            this.routeCMRController.getHistory(this.routePartCMR.routePartCMRId, this.type).subscribe(d => {
                this.value = d;
            });
        } else if (!HString.isNullOrNullString(this.signUUID) && this.type != null) {
            this.routeCMRController.getSignUUIDHistory(this.signUUID, this.type).subscribe(d => {
                this.value = d;
            });
        }else {
            this.value = null;
        }
    }

    ngOnInit(): void {
    }
}
