<ng-container *ngIf="totem">
    <div class="page-title">
        {{ 'TOTEMPAGE.STEP.ENTERFINISH.TITLE' | translateIn:totem.language | async}}
    </div>
    <div class="column-container">
        <div class="column-central">
            <div class="page-help" [innerHtml]="getHelp() | translateIn:totem.language | async">
            </div>
        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta">
                <button class="button-action green" (click)="next()">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{getButtonActionCode()| translateIn:totem.language
                        | async}}
                    </div>
                    <div class="name">{{getButtonActionName()| translateIn:totem.language
                        | async}}
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-container>