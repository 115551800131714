import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteCMRController } from '@shared/src/controllers/open/openroutecmr.controller';
import { RoutePartCMRSignatureAgents, RoutePartCMRSignatureAgentsEnum } from '@shared/src/controllers/route/cmr/RoutePartCMRSignatureAgents';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { AlertService, HString, PhaseCardDto, PhaseController } from '@shared/src/public-api';

@Component({
  selector: 'shared-phase-cmr',
  templateUrl: './shared.phase.cmr.component.html',
  styleUrls: ['./shared.phase.cmr.component.scss'],
})
export class SharedPhaseCMRComponent {

  constructor(private phaseController: PhaseController, private openRouteCMRController: OpenRouteCMRController,
    @Inject('HomeController') protected homeController: HomeController,
    @Inject('AlertService') protected alertService: AlertService) {

  };

  public __phase: PhaseCardDto;
  @Input()
  set phase(value: PhaseCardDto) {
    if (this.__phase === value)
      return;
    this.__phase = value;
    this.loadData();
  }
  get phase(): PhaseCardDto {
    return this.__phase;
  }

  getUUID() {
    return this.uuid;
  }
  getAgent() {
    return this.agent;
  }


  public uuid: string = "";
  public uri: string = "";
  public agent: RoutePartCMRSignatureAgents = RoutePartCMRSignatureAgents.none;

  public options: OptionsStripDto = null;
  public actions: ActionsStripDto = null;
  public optionSelected: string;

  public clickOption(option: OptionDto) {
    this.optionSelected = option.optionId;
    if(this.optionSelected != "QR"){
      let agents = HString.toLowerCase(option.optionId) as keyof typeof RoutePartCMRSignatureAgents;
      this.agent = RoutePartCMRSignatureAgents[agents] || RoutePartCMRSignatureAgents.none;
      this.loadActinos();
      this.phaseController.getSignUUIDURI(this.phase.phaseId, this.agent).subscribe(d => {
        this.uri = d;
      });
    }else{

      this.loadActinos();
      this.uri = this.homeController.getPhaseToExworkCMRsUUIDQR(this.uuid);
    }
  }
  public clickAction(action: ActionDto) {
    switch (action.actionId) {
      case "OPENLINK":
        window.open(this.uri);
        break;
      case "COPYLINK":
        this.alertService.copyToClipboard(this.uri).subscribe(e => { });
        break;
      case "SENDMSGSIGNATURE":
        if(this.phase != null)
          return this.phaseController.sendMessageSignature(this.phase.phaseId).subscribe(d => {});
        break;
      case "REMOVESIGNATURE":
        if(this.uuid != null && this.agent != null && this.agent != RoutePartCMRSignatureAgents.none)
          return this.openRouteCMRController.doRemoveSignature(this.uuid, this.agent).subscribe(d => {
            this.uuid = null;
            this.loadData()
          });
        break;
    }
  }


  ngOnInit() {

  }
  public loadData() {
    this.phaseController.getSignUUID(this.phase.phaseId).subscribe(d => {
      this.uuid = d;

      if (this.uuid != null && this.uuid != "" && this.options == null) {
        this.options = new OptionsStripDto();
        this.options.add(OptionDto.build(RoutePartCMRSignatureAgentsEnum.ID(RoutePartCMRSignatureAgents.sender), RoutePartCMRSignatureAgentsEnum.CAPTION(RoutePartCMRSignatureAgents.sender), RoutePartCMRSignatureAgentsEnum.HELP(RoutePartCMRSignatureAgents.sender), "", 10, -1, -1, false, false))
        this.options.add(OptionDto.build(RoutePartCMRSignatureAgentsEnum.ID(RoutePartCMRSignatureAgents.carrier), RoutePartCMRSignatureAgentsEnum.CAPTION(RoutePartCMRSignatureAgents.carrier), RoutePartCMRSignatureAgentsEnum.HELP(RoutePartCMRSignatureAgents.carrier), "", 20, -1, -1, false, false))
        this.options.add(OptionDto.build(RoutePartCMRSignatureAgentsEnum.ID(RoutePartCMRSignatureAgents.driver), RoutePartCMRSignatureAgentsEnum.CAPTION(RoutePartCMRSignatureAgents.driver), RoutePartCMRSignatureAgentsEnum.HELP(RoutePartCMRSignatureAgents.driver), "", 30, -1, -1, true, true))
        this.options.add(OptionDto.build(RoutePartCMRSignatureAgentsEnum.ID(RoutePartCMRSignatureAgents.consignee), RoutePartCMRSignatureAgentsEnum.CAPTION(RoutePartCMRSignatureAgents.consignee), RoutePartCMRSignatureAgentsEnum.HELP(RoutePartCMRSignatureAgents.consignee), "", 40, -1, -1, false, false))

        this.options.add(OptionDto.build("QR", "SHAREDPHASECMR.QR.CAPTION", "SHAREDPHASECMR.QR.HELP", "", 50, -1, -1, false, false))

        this.clickOption(this.options.getActualSelected());

        this.loadActinos();

      }
    });
  }

  onSignedChanged(){
    this.loadActinos();
  }

  public loadActinos(){
    if(this.phase == null)
      return;

    if(this.agent == null)
      this.agent = RoutePartCMRSignatureAgents.none

    this.phaseController.getSignActions(this.phase.phaseId, this.agent).subscribe(data => {
      this.actions = ActionsStripDto.buildFromActionsStripDto(data);
    });
  }

}
