import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { GeneralStatus, RouteCardDto } from "@shared/src/public-api";
import { HLong } from "@shared/src/datatypes/HLong";

@Component({
    selector: 'shared-route-childofdivision',
    templateUrl: './shared.route.childofdivision.component.html',
    styleUrls: ['./shared.route.childofdivision.component.scss']
})
export class SharedRouteChildOfDivisionComponent implements OnInit {

    public readonly: boolean = false;

    constructor(public routeController: RouteController, @Inject('HomeController') public homeController: HomeController, @Inject('SecurityController') public securityController: SecurityController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });
    }

    @Input() public viewmode: string = 'divide';
    @Input() public isActiveRoutes: boolean;
    
    private _routeId: number;
    @Input()
    public set routeId(value: number) {
        if (this._routeId === value)
            return;

        this._routeId = value;
        if(!HLong.isNullOrNullLong(this._routeId)){
            this.routeController.getRoute(this._routeId).subscribe(data => {
                this.route = data;
            });
        }

    }
    public get routeId(): number {
        return this._routeId;
    }

    private _route: RouteCardDto;
    @Input()
    public set route(value: RouteCardDto) {
        if (this._route === value)
            return;

        this._route = value;
        this.loadStatus();
        this.setResources();
    }
    public get route(): RouteCardDto {
        return this._route;
    }

    @Output() public refreshRoute: EventEmitter<any> = new EventEmitter<any>();
    onClickButton() {
        if (this.route == null || HLong.isNullOrNullLong(this.route.routeId))
            return;
        if(this.viewmode == 'add'){
            this.routeController.addChildOfDivision(this.route.routeId).subscribe(data => {
                this.route = data;
                this.refreshRoute.next(data);
            });
        }else if(this.viewmode == 'compact'){
            this.routeController.routeCompact(this.route.routeId).subscribe(data => {
                this.route = data;
                this.refreshRoute.next(data);
            });
        }
    }

    ngOnInit(): void {
    }

    onDeleteChildOfDivision(child: RouteCardDto) {
        if (this.route == null || HLong.isNullOrNullLong(this.route.routeId) || child == null || HLong.isNullOrNullLong(child.routeId))
            return;
        this.routeController.deleteChildOfDivision(this.route.routeId, child.routeId).subscribe(data => {
            this.route = data;
            this.refreshRoute.next(data);
        });
    }

    public childsInprogress: number = 0;
    public childsDone: number = 0;
    public childsPromised: number = 0;

    public activeChildsCount: number = 0;

    public activeChilds: RouteCardDto[] = new Array();
    
    loadStatus() {
        this.childsPromised=0;
        this.childsInprogress=0;
        this.childsDone=0;
        this.activeChilds = new Array();
        if(this.route && this.route.childs){
            this.route.childs.forEach(element => {
                if (element.generalStatus != GeneralStatus.canceled.toString())
                    this.activeChilds.push(element);

                if (element.generalStatus == GeneralStatus.promised.toString())
                    this.childsPromised++;
                else if (element.generalStatus == GeneralStatus.inprogress.toString())
                    this.childsInprogress++;
                else if (element.generalStatus == GeneralStatus.done.toString())
                    this.childsDone++;
            });
        }
        this.activeChildsCount = this.activeChilds.length;
    }

    public title: string;
    public help: string;
    public buttontext: string;
    setResources(){
         if(this.viewmode == 'add'){
             this.title = 'ROUTEPAGE.ITEM.DIVIDED.ADDCHILD';
             this.help ='ROUTEPAGE.ITEM.DIVIDED.ADDEXPLANATION';
             this.buttontext = 'ROUTEPAGE.ITEM.DIVIDED.ADDCHILD';
         }else if(this.viewmode == 'compact'){
            this.title ='ROUTEPAGE.ITEM.DIVIDED.COMPACT';
             this.help ='ROUTEPAGE.ITEM.DIVIDED.COMPACTEXPLANATION';
             this.buttontext = 'ROUTEPAGE.ITEM.DIVIDED.COMPACT';
         }else if(this.viewmode == 'delete'){
             this.title = 'ROUTEPAGE.ITEM.DIVIDED.DELETECHILD';
             this.help = 'ROUTEPAGE.ITEM.DIVIDED.DELETEEXPLANATION';
         }else if(this.viewmode == 'info'){
            this.title = 'ROUTEPAGE.ITEM.DELIVERIES.CAPTION';
            this.help = '';
        }
    }

}
